import {
    Fragment,
    useEffect,
    useState
} from "react";
import { useSelector } from "react-redux";

import {
    useParams,
    useNavigate,
    Link
} from "react-router-dom";

import * as hi from "@heroicons/react/24/outline";
import { TbTable } from "react-icons/tb";

import {
    CONTEXT_FACT_ENTITY,
    CONTEXT_TYPES,
    ORG_TYPES,
    USER_ROLES
} from "../lib/consts";
import * as t from "../lib/types";
import {
    classNames,
    prettySmartDateTime,
    setDocumentTitle,
    prettyDateTime,
    downloadObjectAsJson,
    flattenScrapeDocuments
} from "../lib/utils";
import {
    selectEnv,
    selectIsSidebarLarge,
    selectMemberships,
    selectUser
} from "../lib/scraper.slice";
import {
    BackendObj
} from "../lib/backend";
import * as t2 from "../lib/backend/extractions.types.generated";

import {
    LabeledLoadingSpinner,
    LoadingSpinner,
    LoadingSpinnerLimit
} from "../components/LoadingSpinner";
import { Button, ButtonGroup, GroupButtonProps } from "../components/Button";
import { LongText } from "../components/LongText";
import { ConfirmModal } from "../components/ConfirmModal";
import { FullScreenText } from "../components/FullScreen";
import { CopyTextbox } from "../components/CopyTextbox";
import { FieldsTable, HierarchicalFieldsTable } from "../components/FieldsTable";
import { OrgPill } from "../components/OrgPill";
import { SlChemistry } from "react-icons/sl";
import { ITab, Tabs } from "../components/Tabs";
import { Dropdown } from "../components/Dropdown";
import { ExampleItemTables } from "../components/ItemTables";

function EmptyList() {
    const is_sidebar_large = useSelector(selectIsSidebarLarge);

    return <div className={classNames("hidden lg:fixed lg:right-0 lg:inset-y-0 lg:flex lg:flex-row", is_sidebar_large ? "lg:left-64" : "lg:left-20")}>
        <div className="flex justify-center items-center h-screen w-full">
            <div className="text-center">
                <TbTable className="mx-auto h-12 w-12 text-gray-400" />
                <h3 className="mt-2 text-sm font-semibold text-gray-900">No templates</h3>
                <p className="mt-1 text-sm text-gray-500">Get started by creating a new template.</p>
                <div className="mt-6">
                    <Button icon={hi.PlusIcon} text="Create Template" href="/template/new" />
                </div>
            </div>
        </div>
    </div >;
}

type TemplateListProps = {
    orgs: t.IOrganization[];
    templates: t.ITemplateBase[];
    selected_template_uuid?: string;
    can_change_template: boolean;
    onSelectTemplate: (template_uuid: string) => void;
}

function TemplateList(props: TemplateListProps) {
    const { orgs, templates, selected_template_uuid, can_change_template, onSelectTemplate } = props;

    const org_map = new Map<string, t.IOrganization>();
    for (const org of orgs) {
        org_map.set(org.uuid, org);
    }

    return <Fragment>
        <div className="p-2">
            <ul className="divide-y divide-gray-20">
                {templates.map((template) => (
                    <li key={template.uuid} className={classNames(
                        "p-3",
                        can_change_template ? "hover:bg-sky-100 cursor-pointer hover:round" : "cursor-wait",
                        selected_template_uuid === template.uuid ? "bg-gray-200" : "")}
                        onClick={() => onSelectTemplate(template.uuid)}>
                        <div className="font-semibold text-sm flex justify-between truncate">
                            <LongText text={template.name} line_limit={1} />
                        </div>
                        <div className="mt-1 flex justify-between items-center gap-x-2.5 text-xs leading-5 text-gray-400">
                            <span>{prettySmartDateTime(template.created_at)}</span>
                            <div>
                                {org_map.has(template.org_uuid) && <OrgPill
                                    name={org_map.get(template.org_uuid)?.name ?? ""}
                                    type={org_map.get(template.org_uuid)?.type ?? ORG_TYPES.personal} />}
                            </div>
                        </div>
                    </li>
                ))}
            </ul>
        </div >
    </Fragment>;
}

type HistoryRec = {
    rec: t2.IContextAuditRecord;
    diff: any;
}

function TemplateHistoryDiff(props: { rec: HistoryRec }) {
    const { rec } = props;

    const user = useSelector(selectUser);
    const is_admin = user.role === USER_ROLES.admin;

    const helperRenderProperty = (label: string, item: any, key: number) => <Fragment>
        {item && <div key={key}>
            {label}: <span className="text-red-500">{JSON.stringify(item?.__old)}</span> &rarr; <span className="text-green-500">{JSON.stringify(item?.__new)}</span>
        </div>}
    </Fragment>;
    const helperRenderField = (d: any, field_idx: number, key: number) => <div key={key}>
        {d[0] === "+" && <span className="text-green-500">Added field {d[1].name}</span>}
        {d[0] === "~" && <span className="text-green-500">Edited field {rec.rec.fields[field_idx] ? rec.rec.fields[field_idx].name : "?"}</span>}
        {d[0] === "-" && <span className="text-red-500">Deleted field {d[1].name}</span>}
    </div>;
    const helperRenderFact = (d: any, fact_idx: number, key: number) => <div key={key}>
        {d[0] === "+" && <span className="text-green-500">Added fact {d[1].entity}</span>}
        {d[0] === "~" && <span className="text-green-500">Edited fact {rec.rec.facts[fact_idx] ? rec.rec.facts[fact_idx].entity : "?"}</span>}
        {d[0] === "-" && <span className="text-red-500">Deleted fact {d[1].entity}</span>}
    </div>;

    let show_detailed = is_admin;

    const items: JSX.Element[] = [];
    if (rec.diff.name) {
        items.push(helperRenderProperty("Name", rec.diff.name, items.length));
    }
    if (rec.diff.email_address) {
        items.push(helperRenderProperty("Email", rec.diff.email_address, items.length));
    }
    if (rec.diff.type) {
        items.push(helperRenderProperty("Number of rows", rec.diff.type, items.length));
    }
    if (rec.diff.extract_params?.remove_duplicate_records) {
        items.push(helperRenderProperty("Remove duplicate records", rec.diff.extract_params?.remove_duplicate_records, items.length));
    }
    if (rec.diff.extract_params?.default_decimal_separator) {
        items.push(helperRenderProperty("Default decimal separator", rec.diff.extract_params?.default_decimal_separator, items.length));
    }
    if (rec.diff.extract_params?.detect_decimal_separator) {
        items.push(helperRenderProperty("Detect decimal separator", rec.diff.extract_params?.detect_decimal_separator, items.length));
    }
    if (show_detailed && rec.diff.extract_params?.try_auto_heal) {
        items.push(helperRenderProperty("Try auto-heal", rec.diff.extract_params?.try_auto_heal, items.length));
    }
    if (show_detailed && rec.diff.extract_params?.max_partial_responses) {
        items.push(helperRenderProperty("Max partial responses", rec.diff.extract_params?.max_partial_responses, items.length));
    }
    if (show_detailed && rec.diff.extract_params?.prompt_output_format) {
        items.push(helperRenderProperty("Prompt output format", rec.diff.extract_params?.prompt_output_format, items.length));
    }
    if (show_detailed && rec.diff.extract_params?.extraction_strategy) {
        items.push(helperRenderProperty("Extraction strategy", rec.diff.extract_params?.extraction_strategy, items.length));
    }
    if (show_detailed && rec.diff.extract_params?.preprocess_excel_strategy) {
        items.push(helperRenderProperty("Preprocessing excel strategy", rec.diff.extract_params?.preprocess_excel_strategy, items.length));
    }
    if (show_detailed && rec.diff.extract_params?.preprocess_ocr_strategy) {
        items.push(helperRenderProperty("Preprocessing OCR strategy", rec.diff.extract_params?.preprocess_ocr_strategy, items.length));
    }
    if (rec.diff.facts) {
        items.push(<div key={items.length}>Facts</div>);
        rec.diff.facts.map((fact: any, idx: number) => items.push(helperRenderFact(fact, idx, items.length)));
    }
    if (rec.diff.fields) {
        items.push(<div key={items.length}>Fields</div>);
        rec.diff.fields.map((field: any, idx: number) => items.push(helperRenderField(field, idx, items.length)));
    }

    if (items.length === 0) {
        return <div className="text-sm text-gray-500">Internal changes</div>;
    }

    return <Fragment>
        {items}
    </Fragment>;
}

type TemplateHistoryProps = {
    history?: HistoryRec[];
}

function TemplateHistory(props: TemplateHistoryProps) {
    const { history } = props;

    const user = useSelector(selectUser);
    const is_admin = user.role === USER_ROLES.admin;

    const [full_screen_text, setFullScreenText] = useState<string | undefined>(undefined);

    return <Fragment>
        {history === undefined && <div className=""><LoadingSpinnerLimit /></div>}
        {history !== undefined && <table className="min-w-full divide-y divide-gray-300">
            <thead className="bg-gray-50">
                <tr>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Date</th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Author</th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Details</th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">&nbsp;</th>
                </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
                {history && history.map((item, idx) => (
                    <Fragment key={idx}>
                        <tr className="text-sm text-gray-500">
                            <td className="whitespace-nowrap px-3 py-4 ">{prettyDateTime(item.rec.audit_created_at)}</td>
                            <td className="whitespace-nowrap px-3 py-4">{item.rec.audit_created_by_username}</td>
                            <td className="px-3 py-4">
                                {idx === 0 && "Created"}
                                {idx > 0 && <TemplateHistoryDiff rec={item} />}
                            </td>
                            <td className="px-3 py-4 text-right">
                                {is_admin && <button className="text-blue-500" onClick={() => setFullScreenText(JSON.stringify(item.diff, null, 2))}>Show raw</button>}
                            </td>
                        </tr>
                    </Fragment>
                ))}
            </tbody>
        </table>}
        <FullScreenText
            text={full_screen_text || ""}
            show={full_screen_text !== undefined}
            onClose={() => setFullScreenText(undefined)} />
    </Fragment>;
}

type TemplateDetailProps = {
    template?: t.ITemplate;
    endpoints?: t.IEndpointBase[];
    item_count?: number;
}

function TemplateDetail(props: TemplateDetailProps) {
    const { template, endpoints, item_count } = props;

    const is_sidebar_large = useSelector(selectIsSidebarLarge);
    const user = useSelector(selectUser);
    const is_admin = user.role === USER_ROLES.admin;

    const [show_full_screen, setShowFullScreen] = useState<number>(-1);
    const [selected_tab, setSelectedTab] = useState<string>("details");
    const [show_admin_details, setShowAdminDetails] = useState<boolean>(false);
    const [history, setHistory] = useState<HistoryRec[] | undefined>(undefined);
    const [template_examples, setTemplateExamples] = useState<t.ITemplateExample[] | undefined>(undefined);
    const [context_details, setContextDetails] = useState<t.IContextBase | undefined>(undefined);

    useEffect(() => {
        // on context change, reset history
        setHistory(undefined);
        // if we have a template, set the first context as selected for showing details
        if (template !== undefined) {
            setContextDetails(template.contexts[0]);
        }
    }, [template]);

    useEffect(() => {
        if (selected_tab === "examples" && template !== undefined) {
            setTemplateExamples(undefined);
            BackendObj.extractions.getTemplateExamples({ template_uuid: template.uuid })
                .then(({ examples }) => {
                    setTemplateExamples(examples);
                }).catch((err) => {
                    console.error(err);
                });
        }
    }, [template, selected_tab]);

    useEffect(() => {
        if (selected_tab === "history" && context_details !== undefined) {
            BackendObj.extractions.getContextAuditLog({ uuid: context_details.uuid }) // TODO: fix this
                .then((res) => {
                    setHistory(res.audit_log.map((obj, idx) => ({
                        rec: obj,
                        diff: res.diffs[idx]
                    })));
                }).catch((err) => {
                    console.error(err);
                });
        }
    }, [context_details, selected_tab]);

    const handleCopyToClipboard = (text: string) => {
        navigator.clipboard.writeText(text);
    }

    if (template === undefined) {
        return <div className={classNames("hidden lg:fixed lg:right-0 lg:inset-y-0 lg:flex lg:flex-row", is_sidebar_large ? "lg:left-64" : "lg:left-20")}>
            <LoadingSpinner />
        </div>;
    }

    const tabs: ITab[] = [
        { name: "Details", key: "details" },
        { name: "Examples", key: "examples" },
        { name: "History", key: "history", only_admin: true }
    ];

    const changeTab = (tab_name: string) => {
        setSelectedTab(tab_name);
    };

    const split_by_field_data = (template.details.split_by_field ?? []).map((split) => {
        const context = template.contexts.find(c => c.uuid === split.context_uuid);
        if (context === undefined) { return undefined; }
        const field = context?.fields.find(f => f.uuid === split.field_uuid);
        const context_name = context.name.length > 0 ? context.name : `Step ${context.weight_score + 1}`;
        return `${context_name}/${field?.name}`;
    }).filter((x) => x !== undefined).join(", ");

    return <Fragment>
        <div className="mt-2 pl-2 pr-2">
            <dl className="px-4 py-6">
                {template.contexts.map((context, idx) => (<div className="pb-4" key={idx}>
                    <div className="flex flex-row text-sm font-semibold text-gray-900 ">
                        Step {idx + 1}: {context.name}
                    </div>
                    <div key={idx} className="mt-2 mb-6 outer-div">
                        {context.type !== CONTEXT_TYPES.hierarchical && <FieldsTable fields={context.fields} context_type={context.type} />}
                        {context.type === CONTEXT_TYPES.hierarchical && <HierarchicalFieldsTable fields={context.fields} />}
                    </div>
                </div>))}

                <div className="py-6 sm:grid sm:grid-cols-5 sm:gap-4 sm:px-0 items-center">
                    <dt className="text-sm font-medium leading-6 text-gray-900">Email connector</dt>
                    <dd className="text-sm leading-6 text-sky-600 sm:col-span-4 sm:mt-0">
                        <CopyTextbox text={template.email_address} email_pretty_name={template.name} is_email={true} />
                    </dd>
                </div>

                <div className="pt-6">
                    <Tabs tabs={tabs} selected_tab_key={selected_tab} setSelectedTab={changeTab} />
                </div>

                {(selected_tab === "details" && <div className="px-4 pb-6 pt-8 sm:grid sm:grid-cols-10 sm:gap-4 sm:px-0 border-b border-gray-200">
                    <dt className="pl-4 text-sm font-medium leading-6 sm:col-span-3 text-gray-900">Split by field</dt>
                    <dd className="pr-4 text-sm leading-6 text-gray-500 sm:col-span-7    sm:mt-0">
                        {template.details.split_by_field === undefined && "No"}
                        {template.details.split_by_field !== undefined && split_by_field_data}
                    </dd>
                </div>)}

                {(selected_tab === "details" || selected_tab === "history") &&
                    <div className="px-4 pt-6 pb-4 flex flex-row items-center">
                        <span className="text-sm min-w-[120px]">Select Step:</span>
                        <Dropdown
                            values={template.contexts.map((c, idx) => `${idx + 1}. ${c.name}`)}
                            ids={template.contexts.map(c => c.uuid)}
                            selected={context_details?.uuid || ""}
                            onChange={(context_uuid) => setContextDetails(template.contexts.find(c => c.uuid === context_uuid))} />
                    </div>}

                {selected_tab === "details" && <Fragment>
                    {context_details && <Fragment>
                        <div className="px-4 py-6">
                            <div className="flex flex-row text-sm font-medium text-gray-900 ">
                                Static Facts: {context_details.name}
                            </div>
                            <div className="pt-2 text-sm leading-6 text-gray-700">
                                {context_details.facts.length === 0 && <i className="text-gray-400">None</i>}
                                {context_details.facts.length > 0 && <table className="w-full">
                                    <thead>
                                        <tr>
                                            <th className="py-1 px-4 bg-gray-100 border border-gray-200 cursor-text hover:bg-sky-100 text-left font-normal align-top">Entity</th>
                                            <th className="py-1 px-4 bg-gray-100 border border-gray-200 cursor-text hover:bg-sky-100 text-left font-normal align-top">Role</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {context_details.facts.map((fact, idx) => (
                                            <tr key={idx}>
                                                <td className="py-1 px-4 border">
                                                    {fact.entity === CONTEXT_FACT_ENTITY.sender ? "Email Sender" :
                                                        fact.entity === CONTEXT_FACT_ENTITY.recipient ? "Email Recipient" :
                                                            fact.entity}
                                                </td>
                                                <td className="py-1 px-4 border">{fact.role}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>}
                            </div>
                        </div>
                        <div className="px-4 py-6 sm:grid sm:grid-cols-10 sm:gap-4 sm:px-0 border-t border-gray-200">
                            <dt className="pl-4 text-sm font-medium leading-6 sm:col-span-3 text-gray-900">Remove duplicate records</dt>
                            <dd className="pr-4 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                                {context_details.extract_params.remove_duplicate_records ? "Yes" : "No"}
                            </dd>
                            <dt className="pl-4 text-sm font-medium leading-6 sm:col-span-3 text-gray-900">Default decimal separator</dt>
                            <dd className="pr-4 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                                {context_details.extract_params.default_decimal_separator === "." ? "Decimal dot" : "Decimal comma"}
                            </dd>
                            <dt className="pl-4 text-sm font-medium leading-6 sm:col-span-3 text-gray-900">Detect decimal separator</dt>
                            <dd className="pr-4 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                                {context_details.extract_params.detect_decimal_separator ? "Yes" : "No"}
                            </dd>
                        </div>
                        <div className="px-4 pb-6 sm:grid sm:grid-cols-10 sm:gap-4 sm:px-0">
                            <dt className="pl-4 text-sm font-medium leading-6 sm:col-span-3 text-gray-900">Extractions</dt>
                            <dd className="pr-4 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                                {item_count !== undefined ? item_count : <i className="fas fa-spinner fa-spin" />}
                            </dd>
                            <dt className="pl-4 text-sm font-medium leading-6 sm:col-span-2 text-gray-900">Endpoints</dt>
                            <dd className="pr-4 text-sm leading-6 text-gray-500 sm:col-span-3 sm:mt-0">
                                {endpoints !== undefined && endpoints.length > 0 &&
                                    endpoints.map((endpoint, idx) => (<Fragment key={idx}>{idx > 0 ? "," : ""} <Link key={idx} to={`/endpoints/${endpoint.uuid}`} className="hover:underline">{endpoint.name}</Link></Fragment>))}
                                {endpoints !== undefined && endpoints.length === 0 && "None"}
                                {endpoints === undefined && <i className="fas fa-spinner fa-spin" />}
                            </dd>
                        </div>

                        {show_admin_details && is_admin && <div className="px-4 py-6 sm:grid sm:grid-cols-10 sm:gap-4 sm:px-0 border-t border-gray-200">
                            <dt className="pl-4 text-sm font-medium leading-6 sm:col-span-3 text-gray-400">Code</dt>
                            <dd className="pr-4 text-sm leading-6 text-gray-300 font-mono sm:col-span-2 sm:mt-0">
                                {context_details.code}
                            </dd>
                            <dt className="pl-4 text-sm font-medium leading-6 sm:col-span-3 text-gray-400">Number of rows</dt>
                            <dd className="pr-4 text-sm leading-6 text-gray-300 sm:col-span-2 sm:mt-0">
                                {context_details.type === CONTEXT_TYPES.array && "Many"}
                                {context_details.type === CONTEXT_TYPES.lookup_table && "Many"}
                                {context_details.type === CONTEXT_TYPES.object && "One"}
                            </dd>
                            <dt className="pl-4 text-sm font-medium leading-6 sm:col-span-3 text-gray-400">Extraction strategy</dt>
                            <dd className="pr-4 text-sm leading-6 text-gray-300 sm:col-span-2 sm:mt-0">
                                {context_details.extract_params.extraction_strategy === "standard" && "Standard"}
                                {context_details.extract_params.extraction_strategy === "prepend_header_page" && "Append header pages"}
                            </dd>
                            <dt className="pl-4 text-sm font-medium leading-6 sm:col-span-3 text-gray-400">Preprocessing excel strategy</dt>
                            <dd className="pr-4 text-sm leading-6 text-gray-300 sm:col-span-2 sm:mt-0">
                                {context_details.extract_params.preprocess_excel_strategy === "col_names_sparse" && "Column names (sparse)"}
                                {context_details.extract_params.preprocess_excel_strategy === "col_names_dense_zero" && "Column names (dense with zero)"}
                                {context_details.extract_params.preprocess_excel_strategy === "col_names_dense_empty" && "Column names (dense with empty)"}
                                {context_details.extract_params.preprocess_excel_strategy === "without_col_names" && "Without column names"}
                            </dd>
                            <dt className="pl-4 text-sm font-medium leading-6 sm:col-span-3 text-gray-400">Preprocessing OCR strategy</dt>
                            <dd className="pr-4 text-sm leading-6 text-gray-300 sm:col-span-2 sm:mt-0">
                                {context_details.extract_params.preprocess_ocr_strategy === "simple" && "Simple"}
                                {context_details.extract_params.preprocess_ocr_strategy === "fix_rotation" && "Fix rotation"}
                            </dd>
                            <dt className="pl-4 text-sm font-medium leading-6 sm:col-span-3 text-gray-400">Preprocessing OCR table strategy</dt>
                            <dd className="pr-4 text-sm leading-6 text-gray-300 sm:col-span-2 sm:mt-0">
                                {context_details.extract_params.preprocess_ocr_table_strategy === "plain_text_only" && "Plain text only"}
                                {context_details.extract_params.preprocess_ocr_table_strategy === "markdown_only" && "Markdown only"}
                                {context_details.extract_params.preprocess_ocr_table_strategy === "markdown_and_plain_text" && "Markdown and plain text"}
                            </dd>
                            <dt className="pl-4 text-sm font-medium leading-6 sm:col-span-3 text-gray-400">Rotation-segment handling strategy</dt>
                            <dd className="pr-4 text-sm leading-6 text-gray-300 sm:col-span-2 sm:mt-0">
                                {context_details.extract_params.orientation_segments_strategy === "as_is" && "Keep rotation as they are"}
                                {context_details.extract_params.orientation_segments_strategy === "only_main" && "Only keep the main rotations"}
                                {context_details.extract_params.orientation_segments_strategy === "segment" && "Segment texts by rotation"}
                            </dd>
                            <dt className="pl-4 text-sm font-medium leading-6 sm:col-span-3 text-gray-400">Maximum partial responses</dt>
                            <dd className="pr-4 text-sm leading-6 text-gray-300 sm:col-span-2 sm:mt-0">{context_details.extract_params.max_partial_responses}</dd>
                            <dt className="pl-4 text-sm font-medium leading-6 sm:col-span-3 text-gray-400">Prompt output format</dt>
                            <dd className="pr-4 text-sm leading-6 text-gray-300 sm:col-span-2 sm:mt-0">{context_details.extract_params.prompt_output_format}</dd>
                            <dt className="pl-4 text-sm font-medium leading-6 sm:col-span-3 text-gray-400">Try auto-heal</dt>
                            <dd className="pr-4 text-sm leading-6 text-gray-300 sm:col-span-2 sm:mt-0">
                                {context_details.extract_params.try_auto_heal ? "Yes" : "No"}
                            </dd>
                        </div>}

                        {show_admin_details && is_admin && <div className="px-4 py-6 sm:grid sm:grid-cols-10 sm:gap-4 sm:px-0 border-t border-gray-200">
                            <dt className="pl-4 text-sm font-medium leading-6 sm:col-span-3 text-gray-400">Extract</dt>
                            <dd className="pr-4 text-sm leading-6 text-gray-300 sm:col-span-2 sm:mt-0">{context_details.extract_params.models_overrides.scrape_extract || "/"}</dd>
                            <dt className="pl-4 text-sm font-medium leading-6 sm:col-span-3 text-gray-400">Heal</dt>
                            <dd className="pr-4 text-sm leading-6 text-gray-300 sm:col-span-2 sm:mt-0">{context_details.extract_params.models_overrides.scrape_heal || "/"}</dd>
                            <dt className="pl-4 text-sm font-medium leading-6 sm:col-span-3 text-gray-400">Summarize</dt>
                            <dd className="pr-4 text-sm leading-6 text-gray-300 sm:col-span-2 sm:mt-0">{context_details.extract_params.models_overrides.scrape_summarize || "/"}</dd>
                            <dt className="pl-4 text-sm font-medium leading-6 sm:col-span-3 text-gray-400">Focused Summarize</dt>
                            <dd className="pr-4 text-sm leading-6 text-gray-300 sm:col-span-2 sm:mt-0">{context_details.extract_params.models_overrides.scrape_focused_summarize || "/"}</dd>
                            <dt className="pl-4 text-sm font-medium leading-6 sm:col-span-3 text-gray-400">Decimal Separator</dt>
                            <dd className="pr-4 text-sm leading-6 text-gray-300 sm:col-span-2 sm:mt-0">{context_details.extract_params.models_overrides.decimal_separator || "/"}</dd>
                            <dt className="pl-4 text-sm font-medium leading-6 sm:col-span-3 text-gray-400">OC Date Validation</dt>
                            <dd className="pr-4 text-sm leading-6 text-gray-300 sm:col-span-2 sm:mt-0">{context_details.extract_params.models_overrides.oc_date_validation || "/"}</dd>
                            <dt className="pl-4 text-sm font-medium leading-6 sm:col-span-3 text-gray-400">Default</dt>
                            <dd className="pr-4 text-sm leading-6 text-gray-300 sm:col-span-2 sm:mt-0">{context_details.extract_params.models_overrides.default || "/"}</dd>
                            <dt className="pl-4 text-sm font-medium leading-6 sm:col-span-3 text-gray-400">Default fast</dt>
                            <dd className="pr-4 text-sm leading-6 text-gray-300 sm:col-span-2 sm:mt-0">{context_details.extract_params.models_overrides.default_fast || "/"}</dd>
                        </div>}

                        {show_admin_details && is_admin && <div className="px-4 py-6 sm:grid sm:grid-cols-10 sm:gap-4 sm:px-0 border-t border-gray-200">
                            <dt className="pl-4 text-sm font-medium leading-6 sm:col-span-3 text-gray-400">Admin After General Scrape</dt>
                            <dd className="pr-4 text-sm leading-6 text-gray-300 sm:col-span-2 sm:mt-0">{context_details.extract_params.admin_prompts.admin_after_scrape_system ? "YES" : "NO"}</dd>
                            <dt className="pl-4 text-sm font-medium leading-6 sm:col-span-3 text-gray-400">Admin After Specific Scrape</dt>
                            <dd className="pr-4 text-sm leading-6 text-gray-300 sm:col-span-2 sm:mt-0">{context_details.extract_params.admin_prompts.admin_after_scrape_user ? "YES" : "NO"}</dd>
                            <dt className="pl-4 text-sm font-medium leading-6 sm:col-span-3 text-gray-400">Admin After General Partial Scrape</dt>
                            <dd className="pr-4 text-sm leading-6 text-gray-300 sm:col-span-2 sm:mt-0">{context_details.extract_params.admin_prompts.admin_after_partial_scrape_system ? "YES" : "NO"}</dd>
                            <dt className="pl-4 text-sm font-medium leading-6 sm:col-span-3 text-gray-400">Admin After Specific Partial Scrape</dt>
                            <dd className="pr-4 text-sm leading-6 text-gray-300 sm:col-span-2 sm:mt-0">{context_details.extract_params.admin_prompts.admin_after_partial_scrape_user ? "YES" : "NO"}</dd>
                        </div>}

                        {!show_admin_details && is_admin && <div className="px-4 py-6 border-t border-gray-200">
                            <Button text="Show admin details" onClick={() => setShowAdminDetails(true)} icon={hi.ChevronDownIcon} />
                        </div>}

                        {show_admin_details && is_admin && <div className="px-4 py-6 border-t border-gray-200">
                            <Button text="Hide admin details" onClick={() => setShowAdminDetails(false)} icon={hi.ChevronUpIcon} />
                        </div>}
                    </Fragment>}
                </Fragment>}

                {selected_tab === "examples" && <Fragment>
                    {template_examples === undefined && <div className="mt-6"><LoadingSpinnerLimit /></div>}
                    {template_examples !== undefined && template_examples.length === 0 && <div className="px-4 mt-6 text-gray-500 text-sm">No examples</div>}
                    {template_examples !== undefined && template_examples.length > 0 && <Fragment>
                        {template_examples.map((example, idx) => <div key={idx} className="sm:items-start sm:gap-4 sm:p-4 mt-5 text-gray-600 text-sm border border-gray-200 bg-gray-50 rounded-md">
                            <div className="pl-2 mb-3 flex flex-row max-w-5xl ">
                                <div className="font-semibold flex flex-row">
                                    {idx + 1}. {example.item.name}
                                    <hi.ClipboardDocumentIcon
                                        className="h-4 w-4 ml-2 text-gray-400 hover:text-gray-600 cursor-pointer"
                                        onClick={() => handleCopyToClipboard(flattenScrapeDocuments(example.item.documents))} />
                                    <hi.ArrowTopRightOnSquareIcon
                                        className="h-4 w-4 ml-2 text-gray-400 cursor-pointer"
                                        onClick={() => setShowFullScreen(idx)} />
                                </div>
                            </div>
                            {example.comment.length > 0 && <div className="pl-2 my-3 text-sm max-w-5xl "><span className="text-xs">[{prettySmartDateTime(example.item.created_at)}]</span> {example.comment}</div>}
                            <div className="p-2 mt-3 border-gray-200 border rounded shadow bg-white max-w-5xl">
                                <LongText text={flattenScrapeDocuments(example.item.documents)} line_limit={5} />
                            </div>
                            <ExampleItemTables template={template} item={example.item} />
                        </div>)}
                    </Fragment>}
                </Fragment>}

                {selected_tab === "history" && <div className="py-6">
                    <TemplateHistory history={history} />
                </div>}
            </dl>
        </div>
        {/* Overlay box showing input text */}
        {template_examples && <FullScreenText
            text={flattenScrapeDocuments(template_examples[show_full_screen]?.item.documents) || ""}
            show={show_full_screen >= 0}
            onClose={() => setShowFullScreen(-1)} />}
    </Fragment>;
}

export function Templates() {
    const navigate = useNavigate();
    const { selected_template_uuid } = useParams<{ selected_template_uuid: string | undefined }>();

    const is_sidebar_large = useSelector(selectIsSidebarLarge);
    const env = useSelector(selectEnv);
    const user = useSelector(selectUser);
    const memberships = useSelector(selectMemberships);

    const [show_items, setShowItems] = useState(true);
    const [templates, setTemplates] = useState<t.ITemplateBase[] | undefined>(undefined);
    const [selected_template, setSelectedTemplate] = useState<t.ITemplate | undefined>(undefined);
    const [selected_template_details, setSelectedTemplateDetails] = useState<{ endpoints?: t.IEndpointBase[], item_count?: number }>({});
    const [show_confirm, setShowConfirm] = useState(false);
    const [allow_remove, setAllowRemove] = useState(true);
    const [is_loading, setIsLoading] = useState(false);
    const [is_cloning, setIsCloning] = useState(false);

    const orgs = memberships.map((membership) => membership.org);
    const admin_org_uuids = memberships.filter((m) => (m.role === "admin" || m.role === "editor")).map((m) => m.org.uuid);

    useEffect(() => {
        setIsLoading(true);
        BackendObj.extractions.listTemplates({})
            .then(({ templates: new_templates }) => {
                setTemplates(new_templates);
                setIsLoading(false);
            });
    }, []);

    useEffect(() => {
        if (templates === undefined) { return; }
        const template_uuid = selected_template_uuid || templates[0]?.uuid || undefined;
        if (template_uuid !== undefined) {
            navigate(`/templates/${template_uuid}`)
        }
    }, [templates, selected_template_uuid, navigate]);

    useEffect(() => {
        if (selected_template_uuid === undefined) { return; }
        setIsLoading(true);
        BackendObj.extractions.getTemplate({ template_uuid: selected_template_uuid })
            .then(({ template, endpoints, item_count }) => {
                setSelectedTemplate(template);
                setSelectedTemplateDetails({ endpoints, item_count });
                setIsLoading(false);
            })
            .catch((err) => {
                setIsLoading(false);
            });
    }, [selected_template_uuid]);

    useEffect(() => {
        if (selected_template === undefined) {
            setDocumentTitle("Templates", env);
        } else {
            setDocumentTitle(`Templates - ${selected_template.name}`, env);
        }
    }, [selected_template, env]);

    const selectTemplate = (template_uuid: string) => {
        if (is_loading) { return; }
        navigate(`/templates/${template_uuid}`)
    }

    const onRemoveTemplate = async (template_uuid: string) => {
        setAllowRemove(false);
        // remember index of selected context, so we can select the one next to it after deletion
        const template_idx = templates ? templates.findIndex((template) => template.uuid === template_uuid) : 0;
        setTemplates(undefined);
        // delete from backend
        await BackendObj.extractions.deleteTemplate({ template_uuid });
        // download fresh list of contexts
        setIsLoading(true);
        BackendObj.extractions.listTemplates({})
            .then(({ templates: new_templates }) => {
                setTemplates(new_templates);
                // select next context
                if (new_templates.length > 0) {
                    if (0 < template_idx && template_idx <= new_templates.length) {
                        navigate(`/templates/${new_templates[template_idx - 1].uuid}`)
                    } else {
                        navigate(`/templates/${new_templates[0].uuid}`)
                    }
                }
                setIsLoading(false);
                setAllowRemove(true);
            }).catch((err) => {
                setIsLoading(false);
                setAllowRemove(true);
            });
    };

    const onRemoveClose = async (is_remove: boolean) => {
        setShowConfirm(false);
        if (selected_template !== undefined && is_remove) {
            onRemoveTemplate(selected_template.uuid);
        }
    };

    const downloadTemplateDef = async () => {
        if (selected_template === undefined) { return; }
        const { data: template_def } = await BackendObj.extractions.exportTemplate({ template_uuid: selected_template.uuid });
        downloadObjectAsJson(template_def, `template_def_${selected_template.uuid}.json`);
    };

    const cloneTemplate = async () => {
        if (selected_template === undefined) { return; }
        // commit as new template
        setIsLoading(true);
        setIsCloning(true);
        try {
            // create new template
            const { template_uuid } = await BackendObj.extractions.createTemplate({
                template: {
                    name: `Copy of ${selected_template.name}`,
                    org_uuid: selected_template.org_uuid,
                    details: selected_template.details
                },
                contexts: selected_template.contexts.map((context) => ({
                    name: context.name,
                    code: context.code,
                    org_uuid: context.org_uuid,
                    facts: context.facts,
                    fields: context.fields,
                    postprocess: context.postprocess,
                    type: context.type,
                    extract_params: context.extract_params,
                    overrides: context.overrides,
                    weight_score: context.weight_score
                }))
            });
            // download fresh list of templates
            const { templates: new_templates } = await BackendObj.extractions.listTemplates({});
            setTemplates(new_templates);
            // navigate to new context
            navigate(`/templates/${template_uuid}`);
        } catch (err) {
            console.error(err);
        }
        setIsCloning(false);
        setIsLoading(false);
    };

    const is_admin = user.role === USER_ROLES.admin;
    const is_org_admin = selected_template && admin_org_uuids.includes(selected_template.org_uuid);

    // if undefined we are still loading
    if (templates === undefined) {
        return <div className={classNames("hidden lg:fixed lg:right-0 lg:inset-y-0 lg:flex lg:flex-row", is_sidebar_large ? "lg:left-64" : "lg:left-20")}>
            <LoadingSpinner />
        </div>;
    }

    if (is_cloning) {
        return <div className={classNames("hidden lg:fixed lg:right-0 lg:inset-y-0 lg:flex lg:flex-row", is_sidebar_large ? "lg:left-64" : "lg:left-20")}>
            <LabeledLoadingSpinner text="Creating a copy of the template" />
        </div>;
    }

    if (templates.length === 0) {
        return <EmptyList />;
    }

    const edit_buttons: GroupButtonProps[] = [
        { icon: hi.ArrowDownTrayIcon, text: "Definition", onClick: downloadTemplateDef, skip: !is_admin },
        { icon: hi.DocumentDuplicateIcon, text: "Clone", onClick: cloneTemplate, skip: !is_org_admin },
        { icon: hi.PencilSquareIcon, text: "Edit", href: `/template/${selected_template?.uuid}/edit`, skip: !is_org_admin },
        { icon: hi.TrashIcon, text: "Remove", onClick: () => setShowConfirm(true), skip: !is_org_admin }
    ];

    const remove_message = ["Existing extractions will no longer be visible after you remove the template."];
    if (selected_template_details && selected_template_details.endpoints && selected_template_details.endpoints.length > 0) {
        remove_message.push(`Removing this template will also remove all associated endpoints: ${selected_template_details.endpoints.map((e) => e.name).join(", ")}`);
    }

    return <Fragment>
        <div className={classNames("hidden lg:fixed lg:right-0 lg:h-16 lg:flex lg:flex-row border-b-gray-200 border-b", is_sidebar_large ? "lg:left-64" : "lg:left-20")}>
            <div className="basis-1/4">
                <div className="md:flex md:items-center md:justify-between p-4 sticky">
                    <div className="min-w-0 flex-1">
                        <Button icon={hi.PlusIcon} tooltip="Create new template" href="/template/new" highlight={true} />
                    </div>
                </div>
            </div>
            <div className="basis-3/4">
                <div className="md:flex md:items-center md:justify-between p-4">
                    <div className="min-w-0 flex-1">
                        <h2 className="text-xl font-semibold leading-7 text-gray-900 sm:truncate sm:text-2xl sm:tracking-tight">
                            {is_sidebar_large ? "" : "Process Template: "}
                            {selected_template ? selected_template.name : ""}
                        </h2>
                    </div>
                    <div className="flex">
                        <Fragment>
                            <ButtonGroup buttons={edit_buttons} disabled={!allow_remove || is_loading} />
                            {selected_template && <Button icon={SlChemistry} text="Extract" href={`/extraction/new/${selected_template.uuid}`} highlight={true} />}
                            {!selected_template && <Button icon={SlChemistry} text="Extract" href={"/extraction/new/"} highlight={true} />}
                        </Fragment>
                    </div>
                </div>
            </div>
        </div>
        <div className={classNames("hidden lg:fixed lg:right-0 lg:inset-y-0 lg:top-16 lg:flex lg:flex-row", is_sidebar_large ? "lg:left-64" : "lg:left-20")}>
            <div className="basis-1/4 overflow-y-auto bg-white border-r-gray-200 border-r">
                <div className="h-auto ">
                    <TemplateList
                        orgs={orgs}
                        templates={templates}
                        selected_template_uuid={selected_template_uuid}
                        can_change_template={!is_loading}
                        onSelectTemplate={selectTemplate} />
                </div>
            </div>
            <div className="basis-3/4 overflow-y-auto">
                <TemplateDetail
                    template={selected_template}
                    endpoints={selected_template_details.endpoints}
                    item_count={selected_template_details.item_count} />
            </div>
        </div >
        <div className="lg:hidden w-full">
            {show_items && <TemplateList
                orgs={orgs}
                templates={templates}
                selected_template_uuid={selected_template_uuid}
                can_change_template={!is_loading}
                onSelectTemplate={(template_uuid) => { selectTemplate(template_uuid); setShowItems(false); }} />
            }
            {!show_items && <div className="p-4 border-t-2 border-gray-50">
                <div className="px-1">
                    <Button icon={hi.ArrowLeftIcon} text="Back" onClick={() => setShowItems(true)} />
                </div>
                <TemplateDetail
                    template={selected_template}
                    endpoints={selected_template_details.endpoints}
                    item_count={selected_template_details.item_count} />
            </div>}
        </div>
        <ConfirmModal open={show_confirm}
            title="Are you sure you want to remove this template?"
            message={remove_message}
            confirm="Remove"
            onClose={onRemoveClose} />
    </Fragment>;
}