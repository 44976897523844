import {
    Fragment,
    RefObject,
    createRef,
    forwardRef,
    useEffect,
    useImperativeHandle,
    useRef,
    useState
} from "react";
import { Link, useNavigate } from "react-router-dom";

import {
    ArrowPathIcon,
    BookOpenIcon,
    CheckIcon,
    ChevronDownIcon,
    ChevronUpIcon,
    PlusCircleIcon,
    StopIcon,
    TrashIcon,
    XMarkIcon
} from "@heroicons/react/24/outline";
import { GoTriangleDown } from "react-icons/go";
import { TbQuote } from "react-icons/tb";

import { Tooltip } from "react-tooltip";
import {
    Dialog,
    Transition
} from "@headlessui/react";

import {
    CONTEXT_TYPES,
    EXTRACT_CONFIRMATION_STATUS
} from "../lib/consts";
import {
    IRecord,
    IContextBase,
    IScrapeBase,
    IItem,
    IItemBase,
    IContextField,
    IScrapeEvalDiff,
    IRecordRaw,
    ISuggestionResult,
    IScrapeDocument,
    ITemplate,
    IScrapeEvalMetrics,
    IFieldNameUuidPair
} from "../lib/types";
import { Backend, BackendObj } from "../lib/backend";
import {
    classNames,
    deepCopy,
    getHierarchicalContextSchema,
    getHierarchicalRecord,
    prettyDateTime,
    prettyNumber
} from "../lib/utils";
import {
    IConfidence,
    IContextNoUUID,
    IExtractConfirmationLog,
    IVerification
} from "../lib/backend/extractions.types.generated";

import { Button } from "./Button";
import { Textbox } from "./Textbox";
import { Dropdown } from "./Dropdown";
import { ConfirmModal } from "./ConfirmModal";
import { Checkbox } from "./Checkbox";
import { FullScreen } from "./FullScreen";
import { Pill } from "./Pill";
import { EditExampleModal } from "./ExampleModals";
import { ErrorMessageBar } from "./ErrorMessageBar";

type SuggestionsProps = {
    suggestions: ISuggestionResult[],
    onRecordChanged: (value: string) => void
}

function Suggestions(props: SuggestionsProps) {
    const { suggestions, onRecordChanged } = props;

    // group by value
    const suggestions_grouped: Map<string, ISuggestionResult[]> = new Map();
    for (const s of suggestions) {
        for (const suggestion of s.suggestions) {
            if (!suggestions_grouped.has(suggestion.value)) {
                suggestions_grouped.set(suggestion.value, []);
            }
            suggestions_grouped.get(suggestion.value)?.push(s);
        }
    }

    // sort values by number of supporting suggestions
    const suggestions_sorted: { value: string, suggestion_names: string[] }[] = [];
    suggestions_grouped.forEach((suggestions, value) => {
        suggestions_sorted.push({
            value, suggestion_names: suggestions.map(s => s.name),
        });
    });
    suggestions_sorted.sort((a, b) => a.suggestion_names.length - b.suggestion_names.length);

    // prepare hints for suggestions
    const suggestions_hints: Map<string, string> = new Map();
    for (const s of suggestions) {
        for (const suggestion of s.suggestions) {
            if (suggestion.hint !== undefined) {
                suggestions_hints.set(`${s.name}_${suggestion.value}`, suggestion.hint);
            }
        }
    }

    if (suggestions_sorted.length === 0) {
        return <div className="px-4 mt-6 sm:px-6 text-sm">
            <div className="py-1 font-semibold">No suggestions</div>
        </div>;
    }

    return <div className="px-4 mt-6 sm:px-6 text-sm">
        <div className="py-1 font-semibold">Suggestions</div>
        {suggestions_sorted.map((s, idx) => <div key={idx} className="flex flex-row items-center space-x-2 py-1 px-1">
            <StopIcon className="w-4 h-4 text-gray-500" />
            <p className="cursor-pointer hover:underline hover:text-sky-600" onClick={() => onRecordChanged(s.value)}>
                {s.value}
            </p>
            <p
                className="cursor-context-menu"
                data-tooltip-id="suggestion-tooltip"
                data-tooltip-html={
                    "Source:<br/>" + s.suggestion_names.map(name => `${name} (${suggestions_hints.get(`${name}_${s.value}`) || "/"})<br/>`).join("")
                }>
                ({s.suggestion_names.length})
            </p>
        </div>)}
        <Tooltip id="suggestion-tooltip" />
    </div>;
}

type ScrapeCellEditProps = {
    field_idx?: number,
    record_idx?: number,
    field?: IContextField,
    record?: IRecord,
    open: boolean,
    onRecordChanged: (record_idx: number, field_idx: number, value: string) => void,
    onColumnChanged?: (field_idx: number, value: string) => void,
    onClose: () => void
}

function ScrapeCellEdit(props: ScrapeCellEditProps) {
    const { field_idx, record_idx, field, record, open, onRecordChanged, onColumnChanged, onClose } = props;

    if (field_idx === undefined || record_idx === undefined || !field || !record) {
        return null;
    }

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={onClose}>
                <div className="fixed inset-0" />

                <div className="fixed inset-0 overflow-hidden">
                    <div className="absolute inset-0 overflow-hidden">
                        <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                            <Transition.Child
                                as={Fragment}
                                enter="transform transition ease-in-out duration-500 sm:duration-700"
                                enterFrom="translate-x-full"
                                enterTo="translate-x-0"
                                leave="transform transition ease-in-out duration-500 sm:duration-700"
                                leaveFrom="translate-x-0"
                                leaveTo="translate-x-full"
                            >
                                <Dialog.Panel className="pointer-events-auto w-screen max-w-xl">
                                    <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                                        <div className="px-4 sm:px-6">
                                            <div className="flex items-start justify-between">
                                                <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                                                    {record_idx + 1} - {field.name}
                                                </Dialog.Title>
                                            </div>
                                        </div>
                                        <div className="relative mt-6 px-4 sm:px-6 w-full">
                                            <Textbox value={`${record.val[field.name]}`} onChange={value => onRecordChanged(record_idx, field_idx, value)} />
                                        </div>
                                        {record.verification && record.verification[field.name] && <div className="px-4 mt-6 sm:px-6 text-sm">
                                            <div className="py-1 font-semibold">Verifications</div>
                                            {record.verification[field.name].map((v, idx) => <div key={idx} className="flex flex-row items-center space-x-2 py-1 px-1"
                                                data-tooltip-id="verification-tooltip"
                                                data-tooltip-html={v.sample_row ?
                                                    `<table>${v.sample_row.map((r) => `<tr><td>${r[0]}</td><td>${r[1]}</td></tr>`).join("")}</table>` : undefined
                                                }>
                                                {v.status === "match" && <CheckIcon className="w-4 h-4 text-green-500" />}
                                                {v.status === "no_match" && <XMarkIcon className="w-4 h-4 text-red-500" />}
                                                <p className="font-mono text-xs">{v.name}</p>
                                            </div>)}
                                            <Tooltip id="verification-tooltip" place="bottom" />
                                        </div>}
                                        {record.suggestion && record.suggestion[field.name] &&
                                            <Suggestions
                                                suggestions={record.suggestion[field.name]}
                                                onRecordChanged={value => onRecordChanged(record_idx, field_idx, value)} />}
                                        <div className="mt-6 flex flex-row items-center px-4 sm:px-6">
                                            {record.confidence && record.confidence[field.name] && <p className="text-xs text-gray-500">
                                                Confidence: {prettyNumber(100 * record.confidence[field.name], 0)}
                                            </p>}
                                            <div className="grow" />
                                            {onColumnChanged && <Button text="Set to Whole Column" onClick={() => onColumnChanged(field_idx, `${record.val[field.name]}`)} />}
                                            <Button text="Close" onClick={onClose} />
                                        </div>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </div>
            </Dialog>
        </Transition.Root >
    )
}

type ScrapeTableProps = {
    context?: IContextBase,
    scrape: IScrapeBase,
    onRecordsChanged: (records: IRecord[]) => Promise<void>,
    setIsRecordChanged: (is_changed: boolean) => void,
    setIsRequireFail: (require_fail: boolean) => void
}

interface IScrapeTableHandle {
    handleSave: (is_verify_and_save: boolean) => Promise<void>;
    handleVerify: () => Promise<void>;
    handleUndo: () => Promise<void>;
}

function getDeepCopyRecords(scrape: IScrapeBase, is_object: boolean): IRecord[] {
    // in case we have an object, we need to make sure we have at least one record
    if (is_object && scrape.records.length === 0) {
        const record: IRecord = { val: {}, val_raw: {} };
        for (const field of scrape.field_name_uuid_pairs) {
            record.val[field.name] = "";
            record.val_raw[field.name] = "";
        }
        return [record];
    }
    // in case we have an array or enough records for an object, we can just deep copy
    return deepCopy(scrape.records);
}

const ScrapeTable = forwardRef<IScrapeTableHandle, ScrapeTableProps>((props: ScrapeTableProps, ref) => {
    const { context, scrape, onRecordsChanged, setIsRecordChanged, setIsRequireFail } = props;

    const is_object = context?.type === CONTEXT_TYPES.object;
    const is_array = context?.type === CONTEXT_TYPES.array || context?.type === CONTEXT_TYPES.lookup_table;

    const [records, setRecords] = useState<IRecord[]>(getDeepCopyRecords(scrape, is_object));
    const [edited_records_raw, setEditedRecordsRaw] = useState<{ row: number, col: number }[]>([]);
    const [edited_records, setEditedRecords] = useState<string[]>([]);
    const [is_changed, _setIsChanged] = useState<boolean>(false);
    const [scrape_cell_open, setScrapeCellOpen] = useState<{ row: number, col: number } | undefined>(undefined);

    /// FUNCTIONS THAT CAN BE CALLED FROM PARENT
    useImperativeHandle(ref, () => ({
        async handleSave(is_verify_and_save: boolean) {
            if (is_changed || is_verify_and_save) {
                setIsChanged(false);
                await onRecordsChanged(records);
                setEditedRecords([]);
                setEditedRecordsRaw([]);
            }
        },

        async handleUndo() {
            setRecords(getDeepCopyRecords(scrape, is_object));
            setEditedRecords([]);
            setEditedRecordsRaw([]);
            setIsChanged(false);
        },

        async handleVerify() {
            if (context === undefined) { return; }
            // check if any need to verify
            const is_context_verify = context.fields.some(f => f.verifications || f.suggestions);
            if (!is_context_verify) { return; }
            // verify
            const clean_records = records.map((r): IRecord => ({
                val_raw: r.val_raw,
                val: r.val,
                confidence: r.confidence
            }));
            const verified_records = await Backend.verifyScrape({
                context_uuid: context.uuid,
                records: clean_records
            });
            setRecords(deepCopy(verified_records));
            setEditedRecordsRaw([]);
            setEditedRecords([]);
            setIsChanged(true);
            // check if any required fields fail
            const require_fail = verified_records
                .some(r => r.verification && Object.keys(r.verification)
                    .some(f => r.verification && r.verification[f] && r.verification[f]
                        .some(v => v.status === "no_match" && v.required)));
            setIsRequireFail(require_fail);
        }
    }));

    if (!context) {
        return null;
    }

    const columns = context.fields.map((f, idx) => ({
        title: f.confirm_name ?? f.name,
        field_name: scrape.field_name_uuid_pairs.find(p => p.uuid === f.uuid)?.name ?? undefined,
        idx,
        hide: f.skip_on_confirm === true,
        required: f.verifications && f.verifications.some(v => v.required)
    }));

    const setIsChanged = (is_changed: boolean) => {
        _setIsChanged(is_changed);
        // report up as well to keep track if any table has changed
        if (setIsRecordChanged) {
            setIsRecordChanged(is_changed);
        }
    }

    const markEdited = (record_idx: number, field_idx: number) => {
        const key = `${record_idx}_${field_idx}`;
        if (!edited_records.includes(key)) {
            setEditedRecords((old_edited_records) => [...old_edited_records, key]);
            setEditedRecordsRaw((old_edited_records_raw) => [...old_edited_records_raw, { row: record_idx, col: field_idx }]);
        }
    }

    const isEdited = (record_idx: number, field_idx: number) => {
        const key = `${record_idx}_${field_idx}`;
        return edited_records.includes(key);
    }

    /// TABLE EDITING
    const handleInput = (e: any, row_idx: number, col_idx: number) => {
        setIsChanged(true);
    };

    const handleBlur = (e: any, row_idx: number, col_idx: number) => {
        const value = e.target.innerText.trim();
        const field_name = columns[col_idx].field_name;
        if (field_name && value !== records[row_idx].val[field_name]) {
            const new_records: IRecord[] = [...records];
            new_records[row_idx].val[field_name] = value;
            setRecords(deepCopy(new_records));
            markEdited(row_idx, col_idx);
            setIsChanged(true);
        }
    };

    /// ROW EDITING

    const deleteRow = (row_idx: number) => {
        const new_records: IRecord[] = [
            ...records.slice(0, row_idx),
            ...records.slice(row_idx + 1)
        ];
        setRecords(deepCopy(new_records));
        // update edited records
        // remove any edited records for this row
        const new_edited_records = edited_records_raw.filter(e => e.row !== row_idx);
        // offset any edited records for rows after this one
        new_edited_records.forEach(e => {
            if (e.row > row_idx) {
                e.row -= 1;
            }
        });
        setEditedRecordsRaw(new_edited_records);
        setEditedRecords(new_edited_records.map(e => `${e.row}_${e.col}`));
        setIsChanged(true);
    }

    const addRow = () => {
        const val: IRecordRaw = {};
        for (const column of columns) {
            if (column.field_name !== undefined) {
                val[column.field_name] = "";
            }
        }
        const new_records: IRecord[] = [...records, { val, val_raw: val }];
        setRecords(deepCopy(new_records));
        setIsChanged(true);
    }

    const moveRow = (row_idx: number, direction: "up" | "down") => {
        if (direction === "up" && row_idx === 0) { return; }
        if (direction === "down" && row_idx === records.length - 1) { return; }
        const diff = direction === "up" ? -1 : 1;
        const new_records: IRecord[] = [...records];
        const temp = new_records[row_idx];
        new_records[row_idx] = new_records[row_idx + diff];
        new_records[row_idx + diff] = temp;
        setRecords(deepCopy(new_records));
        // update edited records
        const new_edited_records = edited_records_raw.map(e => {
            if (e.row === row_idx) { e.row += diff; }
            else if (e.row === row_idx + diff) { e.row += diff; }
            return e;
        });
        setEditedRecordsRaw(new_edited_records);
        setEditedRecords(new_edited_records.map(e => `${e.row}_${e.col}`));
        setIsChanged(true);
    }

    /// FIELD SIDE PANEL

    const onRecordChanged = (record_idx: number, field_idx: number, value: string) => {
        const field_name = columns[field_idx].field_name;
        if (field_name !== undefined) {
            const new_records: IRecord[] = [...records];
            new_records[record_idx].val[field_name] = value;
            setRecords(deepCopy(new_records));
            markEdited(record_idx, field_idx);
            setIsChanged(true);
        }
    }

    const onColumnChanged = (field_idx: number, value: string) => {
        const field_name = columns[field_idx].field_name;
        if (field_name !== undefined) {
            const new_records: IRecord[] = records.map(r => {
                r.val[field_name] = value;
                return r;
            });
            setRecords(deepCopy(new_records));
            for (let i = 0; i < records.length; i++) {
                markEdited(i, field_idx);
            }
            setIsChanged(true);
        }
    }

    // shortcuts for case if object
    const record_val: IRecordRaw = records[0]?.val || {}
    const confidence: IConfidence = records[0]?.confidence || {}
    const verification: IVerification = records[0]?.verification || {}

    return <div>
        <div className="px-4 pb-4 space-x-2 flex flex-row items-center max-w-5xl">
            <p className="font-bold text-gray-900">{context.name}</p>
        </div>
        <div className="px-1 outer-div">
            {is_object && <table className="py-4">
                <tbody>
                    {columns.filter(c => !c.hide).map((column, idx) => <tr key={idx}>
                        <td className="py-2 px-2 bg-gray-100 border border-gray-200 text-left text-sm font-semibold align-top" colSpan={3}>
                            {column.title}
                        </td>

                        {column.field_name !== undefined && <td title={`Confidence: ${confidence && confidence[column.field_name] ? prettyNumber(confidence[column.field_name], 2) : "N/A"}`}
                            className={classNames("w-1 border-l border-t border-b border-gray-200 cursor-pointer",
                                confidence && confidence[column.field_name] ?
                                    (confidence[column.field_name] < 0.5 ? "bg-red-100" :
                                        confidence[column.field_name] < 0.8 ? "bg-yellow-100" :
                                            "bg-gray-100") :
                                    "bg-gray-50",
                            )}></td>}

                        {column.field_name === undefined && <td className="w-1 border-l border-t border-b border-gray-200 cursor-pointer bg-gray-50"></td>}

                        {column.field_name !== undefined && <td
                            className={classNames(
                                "border-t border-b border-gray-200 py-2 px-2 min-w-[100px] whitespace-normal overflow-wrap hover:bg-sky-100 text-left text-sm align-top cursor-text",
                                (isEdited(0, column.idx) ? "font-semibold" :
                                    (verification && verification[column.field_name]) ?
                                        (verification[column.field_name].every(v => v.status === "match") ? "text-green-600" :
                                            verification[column.field_name].every(v => v.status === "no_match") ? "text-red-600" :
                                                "text-violet-600") :
                                        ""),
                                (verification && verification[column.field_name]) &&
                                    verification[column.field_name].some(v => v.status === "no_match" && v.required) ? "bg-red-100" : ""
                            )}
                            contentEditable={true}
                            onInput={e => handleInput(e, 0, column.idx)}
                            onBlur={e => handleBlur(e, 0, column.idx)}
                            dangerouslySetInnerHTML={{ __html: record_val[column.field_name] !== undefined ? ("" + (record_val[column.field_name] ?? "")) : "" }}
                        />}

                        {column.field_name === undefined && <td className="border-t border-b border-gray-200 py-1 px-2 min-w-[100px] whitespace-normal overflow-wrap hover:bg-sky-100 text-left text-sm align-top cursor-text"></td>}

                        <td
                            className="px-1 py-2 w-4 align-middle  text-gray-300 border-t border-b border-r border-gray-200 cursor-pointer hover:text-white hover:bg-sky-300"
                            onClick={() => setScrapeCellOpen({ row: 0, col: column.idx })}>
                            <GoTriangleDown className="w-4 h-4" />
                        </td>

                    </tr>)}
                </tbody>
            </table>}

            {is_array && <table className="py-4">
                <thead>
                    <tr>
                        {!is_object && <th className="min-w-[20px] bg-gray-100 border border-gray-200"></th>}
                        {columns.filter(c => !c.hide).map((column, idx) => <Fragment key={idx}>
                            <th className="py-1 px-2 min-w-[100px] max-w-[200px] bg-gray-100 border border-gray-200 text-left text-sm font-semibold align-top" colSpan={3}>
                                {column.title}
                            </th>
                        </Fragment>)}
                    </tr>
                </thead>
                <tbody>
                    {records.map(({ val, confidence, verification }, row_idx) => <tr key={row_idx}>
                        <td className="py-2 px-2 bg-gray-100 border border-gray-200 text-left text-sm font-semibold align-top">{row_idx + 1}</td>
                        {columns.filter(c => !c.hide).map((column) => <Fragment key={column.idx}>
                            {column.field_name !== undefined && <td title={`Confidence: ${confidence && confidence[column.field_name] ? prettyNumber(confidence[column.field_name], 2) : "N/A"}`}
                                className={classNames("w-1 border-l border-t border-b border-gray-200 cursor-pointer",
                                    confidence && confidence[column.field_name] ?
                                        (confidence[column.field_name] < 0.5 ? "bg-red-100" :
                                            confidence[column.field_name] < 0.8 ? "bg-yellow-100" :
                                                "bg-gray-100") :
                                        "bg-gray-50",
                                )}></td>}
                            {column.field_name === undefined && <td className="w-1 border-l border-t border-b border-gray-200 cursor-pointer bg-gray-50"></td>}

                            {column.field_name !== undefined && <td
                                className={classNames(
                                    "border-t border-b border-gray-200 py-2 px-2 min-w-[100px] max-w-[200px] whitespace-normal overflow-wrap hover:bg-sky-100 text-left text-sm align-top cursor-text",
                                    (isEdited(row_idx, column.idx) ? "font-semibold" :
                                        (verification && verification[column.field_name]) ?
                                            (verification[column.field_name].every(v => v.status === "match") ? "text-green-600" :
                                                verification[column.field_name].every(v => v.status === "no_match") ? "text-red-600" :
                                                    "text-violet-600") :
                                            ""),
                                    (verification && verification[column.field_name]) &&
                                        verification[column.field_name].some(v => v.status === "no_match" && v.required) ? "bg-red-100" : ""
                                )}
                                contentEditable={true}
                                onInput={e => handleInput(e, row_idx, column.idx)}
                                onBlur={e => handleBlur(e, row_idx, column.idx)}
                                dangerouslySetInnerHTML={{ __html: val[column.field_name] !== undefined ? ("" + (val[column.field_name] ?? "")) : "" }}
                            />}
                            {column.field_name === undefined && <td className="border-t border-b border-gray-200 py-1 px-2 min-w-[100px] max-w-[200px] whitespace-normal overflow-wrap hover:bg-sky-100 text-left text-sm align-top cursor-text"></td>}

                            <td
                                className="px-1 py-2 w-4 align-middle  text-gray-300 border-t border-b border-r border-gray-200 cursor-pointer hover:text-white hover:bg-sky-300"
                                onClick={() => setScrapeCellOpen({ row: row_idx, col: column.idx })}>
                                <GoTriangleDown className="w-4 h-4" />
                            </td>
                        </Fragment>)}
                        <td
                            className="px-1 py-2 w-4 align-middle bg-gray-100 text-gray-600 border-t border-b  border-gray-200 cursor-pointer hover:text-white hover:bg-sky-300"
                            onClick={() => moveRow(row_idx, "up")}>
                            <ChevronUpIcon className="w-4 h-4" />
                        </td>
                        <td
                            className="px-1 py-2 w-4 align-middle bg-gray-100 text-gray-600 border-t border-b border-r border-gray-200 cursor-pointer hover:text-white hover:bg-sky-300"
                            onClick={() => moveRow(row_idx, "down")}>
                            <ChevronDownIcon className="w-4 h-4" />
                        </td>
                        <td
                            className="px-1 py-2 w-4 align-middle bg-gray-100 text-gray-600 border border-gray-200 cursor-pointer hover:text-white hover:bg-sky-300"
                            onClick={() => deleteRow(row_idx)}>
                            <TrashIcon className="w-4 h-4" />
                        </td>
                    </tr>)}
                    <tr>
                        <td
                            className="py-1 px-2 bg-gray-100 border border-gray-200 text-center text-sm text-gray-600 align-middle cursor-pointer hover:text-white hover:bg-sky-300"
                            onClick={addRow}>
                            +
                        </td>
                    </tr>
                </tbody>
            </table>}
        </div>
        <ScrapeCellEdit
            field_idx={scrape_cell_open ? scrape_cell_open.col : undefined}
            record_idx={scrape_cell_open ? scrape_cell_open.row : undefined}
            field={scrape_cell_open ? context.fields[scrape_cell_open.col] : undefined}
            record={scrape_cell_open ? records[scrape_cell_open.row] : undefined}
            open={scrape_cell_open !== undefined}
            onRecordChanged={onRecordChanged}
            onColumnChanged={is_array ? onColumnChanged : undefined}
            onClose={() => setScrapeCellOpen(undefined)} />
    </div >;
});

type ItemTablesProps = {
    item: IItem;
    onItemUpdate: () => void;
    changeDecimalSeparator: (decimal_separator: "," | ".") => Promise<void>;
};

export function ItemTables(props: ItemTablesProps) {
    const navigate = useNavigate();

    const { item, onItemUpdate, changeDecimalSeparator } = props;

    const [require_fail, setRequireFail] = useState<string[]>([]);
    const [changed_scrapes, setChangedScrapes] = useState<string[]>([]);
    const [is_confirming, setIsConfirming] = useState(false);
    const [is_rejecting, setIsRejecting] = useState(false);
    const [is_removing, setIsRemoving] = useState(false);
    const [is_verifying, setIsVerifying] = useState(false);
    const [is_saving, setIsSaving] = useState(false);
    const [decimal_separator, setDecimalSeparator] = useState<"," | "." | undefined>(undefined);
    const [change_decimal_separator, setChangeDecimalSeparator] = useState<"," | "." | undefined>(undefined);
    const [is_decimal_recompute, setIsDecimalRecompute] = useState(false);
    const [decimal_confirm_open, setDecimalConfirmOpen] = useState(false);
    const [is_cloned, setIsCloned] = useState(false);
    const [is_new_example_modal_open, setIsNewExampleModalOpen] = useState<boolean>(false);
    const [is_being_cloned, setIsBeingCloned] = useState(false);
    const [error_message, setErrorMessage] = useState<string | undefined>(undefined);

    const scrapeRefs = useRef<(RefObject<IScrapeTableHandle>)[]>([]);
    if (scrapeRefs.current.length !== item.scrapes.length) {
        scrapeRefs.current = item.scrapes.map((_, idx) => scrapeRefs.current[idx] ?? createRef<IScrapeTableHandle>());
    }

    // initialize require_edit by checking if any fields that are required fail
    useEffect(() => {
        // go over all verification results for all records in all scrapes and if at least one required field fails, set require_fail
        const require_fail_set = new Set<string>();
        for (const scrape of item.scrapes) {
            for (const record of scrape.records) {
                if (record.verification) {
                    for (const field of Object.keys(record.verification)) {
                        for (const verification_result of record.verification[field]) {
                            if (verification_result.status === "no_match" && verification_result.required) {
                                require_fail_set.add(scrape.uuid);
                            }
                        }
                    }
                }
            }
        }
        setRequireFail([...require_fail_set]);
        // figure out decimal separator
        for (const scrape of item.scrapes) {
            if (scrape.extraction_info.decimal_separator !== undefined) {
                setDecimalSeparator(scrape.extraction_info.decimal_separator);
                break;
            }
        }
        setIsCloned(item?.details.clone_example_item_uuid !== undefined);
    }, [item]);

    // put scrapes and contexts together in pairs
    const scrapes: { scrape: IScrapeBase, context: IContextBase }[] = [];
    for (let i = 0; i < item.scrapes.length; i++) {
        const scrape = item.scrapes[i];
        const context = item.template.contexts.find(c => c.uuid === scrape.context_uuid);
        if (context === undefined) { continue; }
        scrapes.push({ scrape, context });
    }

    // order scrapes so we first have any single-row scrapes, then any multi-row scrapes
    scrapes.sort((a, b) => a.context.weight_score - b.context.weight_score);

    const handleChanged = (scrape_uuid: string, is_changed: boolean) => {
        if (is_changed && !changed_scrapes.includes(scrape_uuid)) {
            setChangedScrapes([...changed_scrapes, scrape_uuid]);
        } else if (!is_changed && changed_scrapes.includes(scrape_uuid)) {
            setChangedScrapes(changed_scrapes.filter(s => s !== scrape_uuid));
        }
    }

    const handleRequireFail = (scrape_uuid: string, is_require_fail: boolean) => {
        if (is_require_fail && !require_fail.includes(scrape_uuid)) {
            setRequireFail([...require_fail, scrape_uuid]);
        } else if (!is_require_fail && require_fail.includes(scrape_uuid)) {
            setRequireFail(require_fail.filter(s => s !== scrape_uuid));
        }
    }

    const handleSave = async (scrape_uuid: string, records: IRecord[]) => {
        const scrape = scrapes.find(s => s.scrape.uuid === scrape_uuid);
        if (scrape) {
            scrape.scrape.records = records;
            // editable items have confirmation status so we can safely assume that confirmation_uuid exists
            await BackendObj.extractions.updateExtractConfirmationScrape({
                confirmation_uuid: item.extract_confirmations_uuid as string,
                scrape_uuid: scrape_uuid,
                records,
                extraction_info: { decimal_separator }
            });
        }
    };

    const initiateSave = async (is_verify_and_save: boolean) => {
        setIsSaving(true);
        for (let idx = 0; idx < scrapes.length; idx++) {
            await scrapeRefs.current[idx].current?.handleSave(is_verify_and_save);
        }
        setIsSaving(false);
    }

    const handleUndo = async () => {
        for (let idx = 0; idx < scrapes.length; idx++) {
            await scrapeRefs.current[idx].current?.handleUndo();
        }
    }

    const handleVerify = async () => {
        setIsVerifying(true);
        for (let idx = 0; idx < scrapes.length; idx++) {
            await scrapeRefs.current[idx].current?.handleVerify();
        }
        setIsVerifying(false);
    }

    const handleVerifyAndSave = async () => {
        await handleVerify();
        await initiateSave(true);
    }

    const handleChangeDecimalSeparator = async (decimal_separator: "," | ".") => {
        setIsDecimalRecompute(true);
        await changeDecimalSeparator(decimal_separator);
        setIsDecimalRecompute(false);
    }

    const handleRemove = async () => {
        setIsRemoving(true);
        setErrorMessage(undefined);
        try {
            await Backend.deleteItem({ item_uuid: item.uuid });
            if (onItemUpdate !== undefined) { onItemUpdate(); }
        } catch (err) {
            setErrorMessage("Error deleting the item");
        }
        setIsRemoving(false);
    };

    const handleRetry = async () => {
        navigate(`/extraction/new/${item.endpoint_uuid ?? item.template_uuid}/${item.uuid}`);
    }

    const handleReject = async () => {
        setIsRejecting(true);
        await BackendObj.extractions.updateExtractConfirmationItemStatus({
            confirmation_uuid: item.extract_confirmations_uuid as string,
            status: EXTRACT_CONFIRMATION_STATUS.rejected
        });
        setIsRejecting(false);
        if (onItemUpdate !== undefined) { onItemUpdate(); }
    }

    const handleConfirm = async () => {
        setIsConfirming(true);
        await BackendObj.extractions.updateExtractConfirmationItemStatus({
            confirmation_uuid: item.extract_confirmations_uuid as string,
            status: EXTRACT_CONFIRMATION_STATUS.confirmed
        });
        setIsConfirming(false);
        if (onItemUpdate !== undefined) { onItemUpdate(); }
    }

    const startCreateExample = () => {
        setIsNewExampleModalOpen(true);
    }

    const createExample = async (comment: string) => {
        setIsNewExampleModalOpen(false);
        if (item === undefined) { return; }
        setIsBeingCloned(true);
        try {
            await BackendObj.extractions.createExampleFromItem({ item_uuid: item?.uuid, comment })
        } catch (err) {
            console.error(err);
        }
        setIsCloned(true);
        setIsBeingCloned(false);
    }

    const is_numeric = item.template.contexts.some(context => context.fields.some(f => f.datatype === "number")) && decimal_separator !== undefined;
    const is_verify = item.template.contexts.some(context => context.fields.some(f => f.verifications || f.suggestions));
    const is_changed = changed_scrapes.length !== 0;
    const is_require_fail = require_fail.length !== 0;

    const disable_buttons = is_confirming || is_rejecting || is_removing || is_being_cloned;

    return <div className="space-y-4">
        {item.details.retry_new_item_uuids !== undefined && item.details.retry_new_item_uuids.length > 0 &&
            <div className="p-4 border bg-red-100 rounded text-gray-900 text-sm max-w-5xl">
                {item.details.retry_new_item_uuids.length === 1 && <p>
                    There is a <Link to={`/confirm/${item.details.retry_new_item_uuids[0]}`} className="text-sky-600 underline">new version</Link> of this extraction.
                </p>}
                {item.details.retry_new_item_uuids.length > 1 && <p>
                    There are {item.details.retry_new_item_uuids.length} new versions of this extraction: {item.details.retry_new_item_uuids.map((uuid, idx) =>
                        <Fragment key={idx}>{idx > 0 && ", "}<Link to={`/confirm/${uuid}`} className="text-sky-600 underline">version {idx + 1}</Link></Fragment>)}
                </p>}
            </div>}

        <div className="p-4 border bg-yellow-100 rounded text-gray-900 max-w-5xl">
            <div className="flex flex-row items-center gap-x-2 text-sm">
                {!is_require_fail && !is_changed && <p>Edit extracted data or confirm extractions.</p>}
                {!is_require_fail && is_changed && <div>
                    <p className="pb-2">Extractions edited and not saved.</p>
                    <p>You need to <b>save</b> or <b>undo</b> changes before you can confirm.</p>
                </div>}
                {is_require_fail && <div>
                    <p className="pb-2">Some required values fail verification (highlighted in red).</p>
                    <p>You need to correct the values and <b>verify</b> before you can confirm.</p>
                </div>}

                <div className="flex-grow"></div>
                <div className="flex flex-row">
                    <Button
                        icon={XMarkIcon}
                        text="Reject"
                        disabled={disable_buttons}
                        onClick={handleReject}
                        loading={is_rejecting} />
                    <Button
                        icon={CheckIcon}
                        text="Confirm"
                        highlight={true}
                        disabled={is_require_fail || is_changed || disable_buttons}
                        onClick={handleConfirm}
                        loading={is_confirming} />
                </div>
            </div>
        </div>

        <div className="flex flex-row items-center max-w-5xl">
            <Button icon={TrashIcon} text="Remove" disabled={disable_buttons} onClick={() => handleRemove()} loading={is_removing} />
            <Button icon={ArrowPathIcon} text="Retry" disabled={disable_buttons} onClick={() => handleRetry()} />
            {is_cloned && <Pill text="Example" type="default" />}
            {!is_cloned && <Button icon={is_being_cloned ? undefined : PlusCircleIcon} text="Examples" disabled={disable_buttons} loading={is_being_cloned} onClick={startCreateExample} />}

            <div className="flex-grow"></div>
            {is_changed && <p className="pr-2 text-gray-500 font-thin text-sm">(unsaved changes)</p>}
            {is_numeric && <div className="pr-2">
                <Dropdown
                    ids={[",", "."]}
                    values={["Decimal comma", "Decimal dot"]}
                    selected={decimal_separator}
                    disabled={is_decimal_recompute}
                    onChange={(id) => { setChangeDecimalSeparator(id as "," | "."); setDecimalConfirmOpen(true); }} />
            </div>}
            <Button text="Undo" highlight={false} disabled={!is_changed} onClick={handleUndo} />
            {!is_verify && <Button text="Save" highlight={true} disabled={!is_changed || is_saving} loading={is_saving} onClick={() => initiateSave(false)} />}
            {is_verify && <Button text="Verify & Save" highlight={true} disabled={is_verifying || is_saving} loading={is_verifying || is_saving} onClick={handleVerifyAndSave} />}
        </div>


        {scrapes.map((scrape, idx) => <ScrapeTable key={idx} ref={scrapeRefs.current[idx]}
            context={scrape.context} scrape={scrape.scrape}
            onRecordsChanged={async (records) => await handleSave(scrape.scrape.uuid, records)}
            setIsRecordChanged={is_changed => handleChanged(scrape.scrape.uuid, is_changed)}
            setIsRequireFail={is_require_fail => handleRequireFail(scrape.scrape.uuid, is_require_fail)}
        />)}

        <ConfirmModal
            open={decimal_confirm_open}
            title="Change Decimal Separator"
            message={["Changing the decimal separator will reset all unsaved changes. Do you want to continue?"]}
            confirm="Change"
            cancel="Cancel"
            onClose={confirm => {
                setDecimalConfirmOpen(false);
                if (confirm && change_decimal_separator) {
                    handleChangeDecimalSeparator(change_decimal_separator);
                } else {
                    setChangeDecimalSeparator(undefined);
                }
            }} />

        <EditExampleModal
            type="add"
            open={is_new_example_modal_open}
            init_comment=""
            onUpdateExample={createExample}
            onClose={() => setIsNewExampleModalOpen(false)} />
        <ErrorMessageBar
            message={error_message}
            clearMessage={() => setErrorMessage(undefined)}
            is_sidebar_large_override={false} />
    </div >;
}

type ViewObjectScrapeTableProps = {
    context: IContextBase,
    scrape: IScrapeBase,
    show_validation_results: boolean,
    show_all_fields: boolean
}

function ViewObjectScrapeTable(props: ViewObjectScrapeTableProps) {
    const { context, scrape, show_validation_results, show_all_fields } = props;

    const fields = show_all_fields ? context.fields.map(f => ({
        title: f.name,
        field_name: scrape.field_name_uuid_pairs.find(p => p.uuid === f.uuid)?.name ?? undefined,
        type: f.type
    })) : context.fields.filter(f => !f.skip_on_confirm).map(f => ({
        title: f.confirm_name ?? f.name,
        field_name: scrape.field_name_uuid_pairs.find(p => p.uuid === f.uuid)?.name ?? undefined,
        type: f.type
    }));

    const record_val: IRecordRaw = scrape.records[0]?.val || {}
    const confidence: IConfidence = scrape.records[0]?.confidence || {}
    const verification: IVerification = scrape.records[0]?.verification || {}

    return <div className="px-1 outer-div">
        <table className="py-4">
            <tbody>
                {fields.map(({ title, field_name, type }, idx) => <tr key={idx}>
                    <td className={classNames(
                        "py-1 px-2  bg-gray-100 border border-gray-200 text-left text-sm font-semibold align-top",
                        type === "compute" ? "italic" : ""
                    )}>
                        {title}
                    </td>
                    <td title={`Confidence: ${field_name && confidence[field_name] ? prettyNumber(confidence[field_name], 2) : "N/A"}`}
                        className={classNames("w-1 border-l border-t border-b cursor-pointer",
                            field_name && confidence[field_name] ?
                                (confidence[field_name] < 0.5 ? "bg-red-100" :
                                    confidence[field_name] < 0.8 ? "bg-yellow-100" :
                                        "bg-gray-100") :
                                "bg-gray-50",
                        )}></td>
                    {field_name === undefined && <td className="border-t border-b border-r py-1 px-2"></td>}
                    {field_name !== undefined && <td
                        className={classNames(
                            "border-t border-b border-r py-1 px-2 min-w-[100px] overflow-hidden hover:bg-sky-100 text-left text-sm align-top cursor-text",
                            (show_validation_results && field_name && verification[field_name]) ?
                                (verification[field_name].every(v => v.status === "match") ? "text-green-600" :
                                    verification[field_name].every(v => v.status === "no_match") ? "text-red-600" :
                                        "text-violet-600") :
                                ""
                        )}
                    >
                        {record_val[field_name] ?? ""}
                    </td>}
                </tr>)}
            </tbody>
        </table>
    </div>
}

type ViewArrayScrapeTableProps = {
    context: IContextBase,
    scrape: IScrapeBase,
    show_validation_results: boolean,
    show_all_fields: boolean
}

function ViewArrayScrapeTable(props: ViewArrayScrapeTableProps) {
    const { context, scrape, show_validation_results, show_all_fields } = props;

    const columns = show_all_fields ? context.fields.map(f => ({
        title: f.name,
        field_name: scrape.field_name_uuid_pairs.find(p => p.uuid === f.uuid)?.name ?? undefined,
        type: f.type
    })) : context.fields.filter(f => !f.skip_on_confirm).map(f => ({
        title: f.confirm_name ?? f.name,
        field_name: scrape.field_name_uuid_pairs.find(p => p.uuid === f.uuid)?.name ?? undefined,
        type: f.type
    }));

    return <div className="px-1 outer-div">
        <table className="py-4">
            <thead>
                <tr>
                    <th className="min-w-[20px] bg-gray-100 border border-gray-200"></th>
                    {columns.map((column, idx) => <th key={idx} className={classNames(
                        "py-1 px-2 min-w-[100px] max-w-[200px] bg-gray-100 border border-gray-200 text-left text-sm font-semibold align-top",
                        column.type === "compute" ? "italic" : ""
                    )} colSpan={2}>
                        {column.title}
                    </th>)}
                </tr>
            </thead>
            <tbody>
                {scrape.records.map(({ val, confidence, verification }, row_idx) => <tr key={row_idx}>
                    <td className="py-1 px-2 bg-gray-100 border border-gray-200 text-left text-sm font-semibold align-top">{row_idx + 1}</td>
                    {columns.map((column, col_idx) => <Fragment key={col_idx}>
                        {column.field_name !== undefined && <td title={`Confidence: ${confidence && confidence[column.field_name] ? prettyNumber(confidence[column.field_name], 2) : "N/A"}`}
                            className={classNames("w-1 border-l border-t border-b cursor-pointer",
                                confidence && confidence[column.field_name] ?
                                    (confidence[column.field_name] < 0.5 ? "bg-red-100" :
                                        confidence[column.field_name] < 0.8 ? "bg-yellow-100" :
                                            "bg-gray-100") :
                                    "bg-gray-50",
                            )}></td>}
                        {column.field_name === undefined && <td className="w-1 border-l border-t border-b bg-gray-50"></td>}

                        {column.field_name !== undefined && <td
                            className={classNames(
                                "border-t border-b border-r py-1 px-2 min-w-[100px] max-w-[200px] overflow-hidden hover:bg-sky-100 text-left text-sm align-top cursor-text",
                                (show_validation_results && verification && verification[column.field_name]) ?
                                    (verification[column.field_name].every(v => v.status === "match") ? "text-green-600" :
                                        verification[column.field_name].every(v => v.status === "no_match") ? "text-red-600" :
                                            "text-violet-600") :
                                    ""
                            )}
                        >
                            {val[column.field_name] ?? ""}
                        </td>}
                        {column.field_name === undefined && <td className="border-t border-b border-r py-1 px-2 min-w-[100px] max-w-[200px] hover:bg-sky-100 text-left text-sm align-top cursor-text"></td>}

                    </Fragment>)}
                </tr>)}
            </tbody>
        </table>
    </div>
}

function HierarchicalRecordFieldQuote(props: { quote: string, input_lines: string[] }) {
    const { quote, input_lines } = props;

    const lines: number[] = [];
    if (/^L\d+$/.test(quote)) {
        // format "L123" return [123]
        lines.push(parseInt(quote.slice(1)));
    } else if (/^L\d+-L\d+$/.test(quote)) {
        // format "L123-L125" return [123, 124, 125]
        const [start, end] = quote.split("-").map(s => parseInt(s.slice(1)));
        lines.push(...Array.from({ length: end - start + 1 }, (_, i) => start + i));
    } else if (/^L\d+(, L\d+)*$/.test(quote)) {
        // format "L123, L125, ... L130" return [123, 125, ..., 130]
        lines.push(...quote.slice(1).split(", ").map(s => parseInt(s)));
    }

    // get identified lines from input lines
    const identified_lines = lines.map(l => input_lines[l] || "");
    // prepare tooltip (cannot use tailwind)
    const tooltip = `<p style="padding-left: 1rem; border-left-width: 2px; color: #111827; border-color: #4b5563">${identified_lines.join("\n<br/>")}</p>`;

    return lines.length > 0 ? <TbQuote
        className="ml-1 w-4 h-4 text-sky-500 cursor-context-menu"
        data-tooltip-id="quote-tooltip"
        data-tooltip-html={tooltip} /> :
        <Fragment />
}

type HierarchicalRecordProps = {
    val: any;
    input_documents: IScrapeDocument[];
    show_all: boolean;
}

export function HierarchicalRecord(props: HierarchicalRecordProps) {
    const { val, input_documents, show_all } = props;

    const input_lines = input_documents.flatMap(d => d.pages).flatMap(p => p.text.split("\n"));

    const has_reference_line_number = (val: any) => {
        return typeof val === "object" && val.hasOwnProperty("reference_line_number");
    }

    const is_empty = (val: any) => {
        if (show_all) { return false; }
        if (val === undefined || val === null) { return true; }
        if (val === "N/A") { return true; }
        if (typeof val === "string" && val.trim() === "") { return true; }
        if (Array.isArray(val) && val.length === 0) { return true; }
        if (typeof val === "object") {
            if (Object.keys(val).length === 0) { return true; }
            if (Object.values(val).every(v => is_empty(v))) { return true; }
        }
        return false;
    }

    if (Array.isArray(val)) {
        return <div className="pl-8">
            <table>
                <tbody>
                    {val.map((val, idx) => <tr key={idx} className="">
                        <td className="pt-3 font-bold text-sm align-top flex flex-row">
                            {idx + 1}
                            {has_reference_line_number(val) && <HierarchicalRecordFieldQuote quote={val.reference_line_number} input_lines={input_lines} />}
                        </td>
                        <td className="pt-2 pl-4 text-sm align-top">
                            <HierarchicalRecord val={val} input_documents={input_documents} show_all={show_all} />
                        </td>
                    </tr>)}
                </tbody>
            </table>
        </div>;
    }

    if (typeof val === "object") {
        // split keys into simple key-value pairs and nested objects
        const simple_keys = Object.keys(val)
            .filter(k => typeof val[k] === "string" && k !== "reference_line_number")
            .filter(k => val[k] !== "N/A");
        const nested_keys = Object.keys(val)
            .filter(k => typeof val[k] !== "string")
            .filter(k => !is_empty(val[k]));

        return <div className="pl-8">
            {simple_keys.length > 0 && <table>
                <tbody>
                    {simple_keys.map((key, idx) => <tr key={idx} className="">
                        <td className="pt-2 font-bold text-sm  align-top">{key}</td>
                        <td className="pt-2 pl-4 text-sm  align-top">{val[key] === "N/A" ? "/" : val[key]}</td>
                    </tr>)}
                </tbody>
            </table>}
            {nested_keys.length > 0 && <ul className="">
                {nested_keys.map((key, idx) => <li key={idx} className="pt-1">
                    <div className="flex flex-row">
                        <span className="font-bold text-sm">{key}</span>
                        {has_reference_line_number(val[key]) && <HierarchicalRecordFieldQuote quote={val[key].reference_line_number} input_lines={input_lines} />}
                    </div>
                    <HierarchicalRecord val={val[key]} input_documents={input_documents} show_all={show_all} />
                </li>)}
            </ul>}
        </div>;
    }

    return <li className="pl-4">
        <p>{val}</p>
    </li>;
}

type ViewHierarchicalScrapeTableProps = {
    context: IContextBase;
    scrape: IScrapeBase;
    input_documents: IScrapeDocument[];
}

function ViewHierarchicalScrapeTable(props: ViewHierarchicalScrapeTableProps) {
    const { context, scrape, input_documents } = props;

    const { context_schema, array_placeholders } = getHierarchicalContextSchema(context.fields);
    const flat_record_val = scrape.records ? scrape.records[0].val : {};
    const { record } = getHierarchicalRecord(flat_record_val, context_schema, array_placeholders);

    return <div className="px-1 max-w-3xl">
        <HierarchicalRecord val={record} input_documents={input_documents} show_all={false} />
        <Tooltip
            id="quote-tooltip" place="top"
            style={{
                backgroundColor: "#f0f9ff",
                color: "#111827",
                borderRadius: "0.5rem",
                padding: "1rem"
            }}
            opacity={1} />
    </div>;
}

type ViewScrapeTableProps = {
    context?: IContextBase;
    scrape: IScrapeBase;
    input_documents: IScrapeDocument[];
    confirmation_status?: string;
    confirmation_log?: IExtractConfirmationLog[];
}

export function ViewScrapeTable(props: ViewScrapeTableProps) {
    const { context, scrape, input_documents, confirmation_status, confirmation_log } = props;

    const [show_all_fields, setShowAllFields] = useState(false);
    const [show_history, setShowHistory] = useState(false);

    if (!context) {
        return null;
    }

    const is_array = context.type === CONTEXT_TYPES.array;
    const is_object = context.type === CONTEXT_TYPES.object;
    const is_hierarchical = context.type === CONTEXT_TYPES.hierarchical;
    const is_lookup_table = context.type === CONTEXT_TYPES.lookup_table;
    // check if at least one field is either hidden or has different title on confirmation screen
    const is_view_confirmed_diff = context.fields.some(f => f.skip_on_confirm === true || f.confirm_name !== undefined);

    // prepare history log scrapes if we have any
    const is_history = confirmation_log !== undefined && confirmation_log.length > 0;
    // order from oldest to newest
    const history_scrapes: IScrapeBase[] = [];
    if (is_history) {
        confirmation_log.sort((a, b) => a.created_at - b.created_at);
        history_scrapes.push({
            uuid: scrape.uuid,
            context_uuid: context.uuid,
            field_name_uuid_pairs: scrape.field_name_uuid_pairs,
            input_item_uuid: scrape.input_item_uuid,
            records: confirmation_log[0].old_records,
            created_at: scrape.created_at,
            extraction_info: scrape.extraction_info
        });
        for (const log of confirmation_log) {
            history_scrapes.push({
                uuid: scrape.uuid,
                context_uuid: context.uuid,
                field_name_uuid_pairs: scrape.field_name_uuid_pairs,
                input_item_uuid: scrape.input_item_uuid,
                records: log.new_records,
                created_at: log.created_at,
                extraction_info: scrape.extraction_info
            });
        }
    }

    return <div>
        <div className="px-4 pb-4 font-bold text-gray-900 space-x-2 flex flex-row items-center">
            <div>{context.name}</div>
            <div className="flex-grow"></div>
            {is_view_confirmed_diff && <div className="flex flex-row items-center font-normal text-sm px-6">
                <Checkbox checked={show_all_fields} setChecked={setShowAllFields} id={`${context.uuid}show_all_fields`} />
                <label htmlFor={`${context.uuid}show_all_fields`} className="ml-2">Show all</label>
            </div>}
            {is_history && <Button icon={BookOpenIcon} text="History" onClick={() => setShowHistory(true)} />}
        </div>

        {scrape.records.length === 0 && <div className="mb-4 mx-2 p-4 border bg-gray-100 rounded text-gray-900 text-sm">
            <p className="pb-2">We were unable to extract any data for template <span className="font-semibold">{context.name}</span>.</p>
            <p className="pb-1">Potential reasons for this:</p>
            <ul className="list-disc list-inside">
                <li className="pb-1/2">The input didn't contain any relevant information about the template.</li>
                <li>Email had attachments in a format that our current extraction algorithms cannot process.</li>
            </ul>
        </div>}

        {is_object && <ViewObjectScrapeTable
            context={context}
            scrape={scrape}
            show_validation_results={confirmation_status !== EXTRACT_CONFIRMATION_STATUS.confirmed}
            show_all_fields={show_all_fields} />}
        {is_array && <ViewArrayScrapeTable
            context={context}
            scrape={scrape}
            show_validation_results={confirmation_status !== EXTRACT_CONFIRMATION_STATUS.confirmed}
            show_all_fields={show_all_fields} />}
        {is_hierarchical && <ViewHierarchicalScrapeTable
            context={context}
            scrape={scrape}
            input_documents={input_documents} />}
        {is_lookup_table && <ViewArrayScrapeTable
            context={context}
            scrape={scrape}
            show_validation_results={confirmation_status !== EXTRACT_CONFIRMATION_STATUS.confirmed}
            show_all_fields={show_all_fields} />}

        <FullScreen show={show_history} onClose={() => setShowHistory(false)}>
            <div className="p-4">
                <div className="font-bold text-lg leading-6">
                    History of changes
                </div>
                {history_scrapes.map((scrape, idx) => <div key={idx} className="mb-4">
                    <div className="pt-8 pb-4">
                        <span className="text-gray-600 text-sm">{prettyDateTime(scrape.created_at)}</span>
                        <span className="ml-4 font-semibold text-gray-600 text-sm">
                            {idx === 0 ? "(Extracted)" : (idx === history_scrapes.length - 1 ? "(Current)" : "")}
                        </span>
                    </div>
                    {is_object && <ViewObjectScrapeTable
                        context={context}
                        scrape={scrape}
                        show_validation_results={true}
                        show_all_fields={show_all_fields} />}
                    {is_array && <ViewArrayScrapeTable
                        context={context}
                        scrape={scrape}
                        show_validation_results={true}
                        show_all_fields={show_all_fields} />}
                    {is_lookup_table && <ViewArrayScrapeTable
                        context={context}
                        scrape={scrape}
                        show_validation_results={true}
                        show_all_fields={show_all_fields} />}

                </div>)}
            </div>
        </FullScreen>
    </div>;
}

type ViewItemTablesProps = {
    item: IItem;
};

export function ViewItemTables(props: ViewItemTablesProps) {
    const { item } = props;

    // put scrapes and contexts together in pairs
    const scrapes: { scrape: IScrapeBase, context: IContextBase }[] = [];
    for (let i = 0; i < item.scrapes.length; i++) {
        const scrape = item.scrapes[i];
        const context = item.template.contexts.find(c => c.uuid === scrape.context_uuid);
        if (context !== undefined) {
            scrapes.push({ scrape, context });
        }
    }

    // order scrapes so we first have any single-row scrapes, then any multi-row scrapes
    scrapes.sort((a, b) => a.context.weight_score - b.context.weight_score);

    return <div className="space-y-12">
        {scrapes.map((scrape, i) => <ViewScrapeTable key={i}
            context={scrape.context} scrape={scrape.scrape}
            input_documents={item.documents}
            confirmation_status={item.extract_confirmations_status}
            confirmation_log={item.extract_confirmation_log !== undefined ?
                item.extract_confirmation_log.filter(ecl => ecl.scrape_uuid === scrape.scrape.uuid) : undefined}
        />)}
    </div >;
}

type DemoTableProps = {
    fields: IContextField[];
    records: IRecord[];
}

export function DemoTable(props: DemoTableProps) {
    const { fields, records } = props;

    return <div>
        <div className="px-1 overflow-x-auto"><table className="py-4">
            <thead>
                <tr>
                    <th className="w-6 bg-gray-100 border border-gray-200"></th>
                    {fields.map((field, idx) => <th key={idx} className="py-1 px-2 min-w-[100px] max-w-[200px] bg-gray-100 border border-gray-200 text-left text-sm font-semibold align-top">
                        {field.name}
                    </th>)}
                </tr>
            </thead>
            <tbody>
                {records.map(({ val: record }, row_idx) => <tr key={row_idx}>
                    <td className="py-1 px-2 bg-gray-100 border border-gray-200 text-left text-sm font-semibold align-top">{row_idx + 1}</td>
                    {fields.map((field, col_idx) => <td
                        key={col_idx}
                        className="py-1 px-2 min-w-[100px] max-w-[200px] border bg-white text-left text-sm align-top cursor-text">
                        {record[field.name]}
                    </td>)}
                </tr>)}
            </tbody>
        </table>
        </div>
    </div>;
}

type ExampleObjectScrapeTableProps = {
    fields: IContextField[];
    records: IRecord[];
    field_name_uuid_pairs: IFieldNameUuidPair[],
}

function ExampleObjectScrapeTable(props: ExampleObjectScrapeTableProps) {
    const { fields, records, field_name_uuid_pairs } = props;

    // prepare field uuid => name map
    const field_uuid_name_map: { [key: string]: string } = {};
    field_name_uuid_pairs.forEach(p => field_uuid_name_map[p.uuid] = p.name);

    const record_val: IRecordRaw = records[0]?.val || {}

    return <div className="max-h-[500px] max-w-4xl pr-6 outer-div  border-gray-200">
        <table className="border-l border-t">
            <tbody>
                {fields.map((field, idx) => <tr key={idx}>
                    <td className={classNames(
                        "py-1 px-2  bg-gray-100 border-r border-b  border-gray-200 text-left text-sm font-semibold align-top w-[250px]",
                        field.type === "compute" ? "italic" : ""
                    )}>
                        {field.name}
                    </td>
                    <td className="border-b border-r py-1 px-2 min-w-[200px] max-w-[350px] overflow-hidden hover:bg-sky-100 bg-white text-left text-sm align-top cursor-text">
                        {record_val[field_uuid_name_map[field.uuid]] ?? record_val[field.name] ?? ""}
                    </td>
                </tr>)}
            </tbody>
        </table>
    </div>;
}

type ExampleArrayScrapeTableProps = {
    fields: IContextField[];
    records: IRecord[];
    field_name_uuid_pairs: IFieldNameUuidPair[],
}

function ExampleArrayScrapeTable(props: ExampleArrayScrapeTableProps) {
    const { fields, records, field_name_uuid_pairs } = props;

    // prepare field uuid => name map
    const field_uuid_name_map: { [key: string]: string } = {};
    field_name_uuid_pairs.forEach(p => field_uuid_name_map[p.uuid] = p.name);

    return <div className="max-h-[500px] outer-div border-l border-t border-gray-200">
        <table className="min-w-full">
            <thead>
                <tr>
                    <th className="sticky left-0 top-0 z-11 min-w-[20px] border-r border-b bg-gray-100 border-gray-200"></th>
                    {fields.map((field, idx) => <th key={idx} className={classNames(
                        "sticky top-0 z-10 py-1 px-2 min-w-[100px] bg-gray-100 border-r border-b border-gray-200 text-left text-sm font-semibold align-top",
                        field.type === "compute" ? "italic" : ""
                    )}>
                        {field.name}
                    </th>)}
                </tr>
            </thead>
            <tbody>
                {records.map(({ val: record }, row_idx) => <tr key={row_idx}>
                    <td className="sticky left-0 z-9 py-1 px-2 border-r border-b bg-gray-100 border-gray-200 text-left text-sm font-semibold align-top">{row_idx + 1}</td>
                    {fields.map((field, col_idx) => <td key={col_idx} className="py-1 px-2 min-w-[100px] border-r border-b bg-white text-left text-sm align-top cursor-text">
                        {record[field_uuid_name_map[field.uuid]] ?? record[field.name] ?? ""}
                    </td>)}
                </tr>)}
            </tbody>
        </table>
    </div>;
}

type ExampleScrapeTableProps = {
    fields: IContextField[];
    context_type: string;
    records: IRecord[];
    field_name_uuid_pairs: IFieldNameUuidPair[],
}

export function ExampleScrapeTable(props: ExampleScrapeTableProps) {
    const { fields, context_type, records, field_name_uuid_pairs } = props;

    return <div className="px-1 py-4">
        {context_type === CONTEXT_TYPES.object && <ExampleObjectScrapeTable fields={fields} records={records} field_name_uuid_pairs={field_name_uuid_pairs} />}
        {context_type !== CONTEXT_TYPES.object && <ExampleArrayScrapeTable fields={fields} records={records} field_name_uuid_pairs={field_name_uuid_pairs} />}
    </div >;
}

type ExampleItemTablesProps = {
    template: ITemplate;
    item: IItemBase;
};

export function ExampleItemTables(props: ExampleItemTablesProps) {
    const { template, item } = props;

    const scrapes: { scrape: IScrapeBase, context: IContextBase }[] = [];
    for (let i = 0; i < item.scrapes.length; i++) {
        const scrape = item.scrapes[i];
        const context = template.contexts.find(c => c.uuid === scrape.context_uuid);
        if (context !== undefined) {
            scrapes.push({ scrape, context });
        }
    }
    scrapes.sort((a, b) => a.context.weight_score - b.context.weight_score);

    return <Fragment>
        {scrapes.map(({ scrape, context }, i) => <div className="pt-4" key={i}>
            {context.name.length > 0 && <div className="px-2 font-medium text-sm">{context.name}</div>}
            <ExampleScrapeTable key={i}
                fields={context.fields}
                context_type={context.type}
                records={scrape.records}
                field_name_uuid_pairs={scrape.field_name_uuid_pairs}
            />
        </div>)}
    </Fragment >;
}

type ExampleCellDiffProps = {
    field: IContextField;
    diff: IScrapeEvalDiff;
    old_records: IRecord[];
    old_field_name_uuid_pairs: IFieldNameUuidPair[],
    new_records: IRecord[];
    new_field_name_uuid_pairs: IFieldNameUuidPair[];
}

function ExampleCellDiff(props: ExampleCellDiffProps) {
    const { field, diff, old_records, old_field_name_uuid_pairs, new_records, new_field_name_uuid_pairs } = props;

    // prepare field uuid => name map
    const old_field_name: string = old_field_name_uuid_pairs.find(p => p.uuid === field.uuid)?.name ?? field.name;
    const new_field_name: string = new_field_name_uuid_pairs.find(p => p.uuid === field.uuid)?.name ?? field.name;

    // first try to find the field by the uuid, then by the name
    const field_diff = diff.diff?.find(d => d.prop === new_field_name) ?? diff.diff?.find(d => d.prop === field.name);

    if (diff.type === "extra") {
        const record = new_records[diff.idx_new].val;
        const val = record[new_field_name] ?? record[field.name];
        return <td className="py-1 px-2 min-w-[200px] max-w-[350px] border-r border-b bg-green-100 text-left text-sm align-top cursor-text">
            {val ?? ""}
        </td>;
    }
    if (diff.type === "missing") {
        const record = old_records[diff.idx_old].val;
        const val = record[old_field_name] ?? record[field.name];
        return <td className="py-1 px-2 min-w-[200px] max-w-[350px] border-r border-b bg-red-100 line-through text-left text-sm align-top cursor-text">
            {val ?? ""}
        </td>;
    }
    if (diff.type === "exact") {
        const record = new_records[diff.idx_new].val;
        const val = record[new_field_name] ?? record[field.name];
        return <td className="py-1 px-2 min-w-[200px] max-w-[350px] border-r border-b bg-white text-left text-sm align-top cursor-text">
            {val ?? ""}
        </td>;
    }
    if (diff.type === "approx") {
        const old_record = old_records[diff.idx_old].val;
        const old_val = old_record[old_field_name] ?? old_record[field.name];
        const new_record = new_records[diff.idx_new].val;
        const new_val = new_record[new_field_name] ?? new_record[field.name];
        return <td className="py-1 px-2 min-w-[200px] max-w-[350px] border-r border-b bg-white text-left text-sm align-top cursor-text">
            {field_diff !== undefined ?
                <Fragment>
                    {old_val !== undefined && <span className="bg-green-100">{new_val}</span>}
                    {old_val !== undefined && new_val !== undefined && <span>&nbsp;/&nbsp;</span>}
                    {new_val !== undefined && <span className="bg-red-100 line-through">{old_val}</span>}
                </Fragment> :
                (new_record[field.name] ?? "")}
        </td>;
    }

    return null;
}

type ExampleDiffObjectScrapeTableProps = {
    fields: IContextField[];
    old_records: IRecord[];
    old_field_name_uuid_pairs: IFieldNameUuidPair[],
    new_records: IRecord[];
    new_field_name_uuid_pairs: IFieldNameUuidPair[];
    diffs: IScrapeEvalDiff[];
}

export function ExampleDiffObjectScrapeTable(props: ExampleDiffObjectScrapeTableProps) {
    const { fields, old_records, old_field_name_uuid_pairs, new_records, new_field_name_uuid_pairs, diffs } = props;

    return <div className="max-h-[500px] max-w-4xl pr-6 outer-div  border-gray-200">
        <table className="border-l border-t">
            <tbody>
                {fields.map((field, idx) => <tr key={idx}>
                    <td className={classNames(
                        "py-1 px-2  bg-gray-100 border-r border-b  border-gray-200 text-left text-sm font-semibold align-top w-[250px]",
                        field.type === "compute" ? "italic" : ""
                    )}>
                        {field.name}
                    </td>
                    {diffs.map((diff, idx) => <ExampleCellDiff
                        key={idx}
                        field={field}
                        diff={diff}
                        old_records={old_records}
                        old_field_name_uuid_pairs={old_field_name_uuid_pairs}
                        new_records={new_records}
                        new_field_name_uuid_pairs={new_field_name_uuid_pairs} />)}
                </tr>)}
            </tbody>
        </table>
    </div>;
}

type ExampleRowDiffProps = {
    fields: IContextField[];
    diff: IScrapeEvalDiff;
    old_records: IRecord[];
    old_field_name_uuid_pairs: IFieldNameUuidPair[],
    new_records: IRecord[];
    new_field_name_uuid_pairs: IFieldNameUuidPair[];
}

function ExampleRowDiff(props: ExampleRowDiffProps) {
    const { fields, diff, old_records, old_field_name_uuid_pairs, new_records, new_field_name_uuid_pairs } = props;

    // prepare field uuid => name map
    const old_field_uuid_name_map: { [key: string]: string } = {};
    old_field_name_uuid_pairs.forEach(p => old_field_uuid_name_map[p.uuid] = p.name);
    const new_field_uuid_name_map: { [key: string]: string } = {};
    new_field_name_uuid_pairs.forEach(p => new_field_uuid_name_map[p.uuid] = p.name);

    if (diff.type === "extra") {
        const record = new_records[diff.idx_new].val;
        return <Fragment>
            {fields.map((field, col_idx) =>
                <td key={col_idx} className="py-1 px-2 min-w-[100px] border-r border-b bg-green-100 text-left text-sm align-top cursor-text">
                    {record[new_field_uuid_name_map[field.uuid]] ?? record[field.name] ?? ""}
                </td>)}
        </Fragment>;
    }
    if (diff.type === "missing") {
        const record = old_records[diff.idx_old].val;
        return <Fragment>
            {fields.map((field, col_idx) =>
                <td key={col_idx} className="py-1 px-2 min-w-[100px] border-r border-b bg-red-100 line-through text-left text-sm align-top cursor-text">
                    {record[old_field_uuid_name_map[field.uuid]] ?? record[field.name] ?? ""}
                </td>)}
        </Fragment>;
    }
    if (diff.type === "exact") {
        const record = new_records[diff.idx_new].val;
        return <Fragment>
            {fields.map((field, col_idx) =>
                <td key={col_idx} className="py-1 px-2 min-w-[100px] border-r border-b bg-white text-left text-sm align-top cursor-text">
                    {record[new_field_uuid_name_map[field.uuid]] ?? record[field.name] ?? ""}
                </td>)}
        </Fragment>;
    }
    if (diff.type === "approx") {
        const old_record = old_records[diff.idx_old].val;
        const new_record = new_records[diff.idx_new].val;
        const diff_fields = diff.diff ? diff.diff.map(d => d.prop) : [];
        return <Fragment>
            {fields.map((field, col_idx) =>
                <td key={col_idx} className="py-1 px-2 min-w-[100px] border-r border-b bg-white text-left text-sm align-top cursor-text">
                    {diff_fields.includes(new_field_uuid_name_map[field.uuid] ?? field.name) ?
                        <Fragment>
                            {(new_record[new_field_uuid_name_map[field.uuid]] ?? new_record[field.name]) !== undefined && <span className="bg-green-100">{new_record[new_field_uuid_name_map[field.uuid]] ?? new_record[field.name]}</span>}
                            {(new_record[new_field_uuid_name_map[field.uuid]] ?? new_record[field.name]) !== undefined && (old_record[old_field_uuid_name_map[field.uuid]] ?? old_record[field.name]) !== undefined && <span>&nbsp;/&nbsp;</span>}
                            {(old_record[old_field_uuid_name_map[field.uuid]] ?? old_record[field.name]) !== undefined && <span className="bg-red-100 line-through">{old_record[old_field_uuid_name_map[field.uuid]] ?? old_record[field.name]}</span>}
                        </Fragment> :
                        (new_record[new_field_uuid_name_map[field.uuid]] ?? new_record[field.name] ?? "")}
                </td>)}
        </Fragment>;
    }

    return null;
}

type ExampleDiffArrayScrapeTableProps = {
    fields: IContextField[];
    old_records: IRecord[];
    old_field_name_uuid_pairs: IFieldNameUuidPair[],
    new_records: IRecord[];
    new_field_name_uuid_pairs: IFieldNameUuidPair[];
    diffs: IScrapeEvalDiff[];
}

export function ExampleDiffArrayScrapeTable(props: ExampleDiffArrayScrapeTableProps) {
    const { fields, old_records, old_field_name_uuid_pairs, new_records, new_field_name_uuid_pairs, diffs } = props;

    return <div className="max-h-[500px] outer-div border-l border-t border-gray-200">
        <table className="min-w-full">
            <thead>
                <tr>
                    <th className="sticky left-0 top-0 z-11 min-w-[20px] bg-gray-100 border border-gray-200"></th>
                    {fields.map((field, idx) => <th key={idx} className="sticky top-0 z-10 py-1 px-2 min-w-[100px] bg-gray-100 border-r border-b border-gray-200 text-left text-sm font-semibold align-top">
                        {field.name}
                    </th>)}
                </tr>
            </thead>
            <tbody>
                {diffs.map((diff, row_idx) => <tr key={row_idx}>
                    <td className="sticky left-0 z-9 py-1 px-2 border-r border-b bg-gray-100 border-gray-200 text-left text-sm font-semibold align-top">{row_idx + 1}</td>
                    <ExampleRowDiff
                        fields={fields}
                        diff={diff}
                        old_records={old_records}
                        old_field_name_uuid_pairs={old_field_name_uuid_pairs}
                        new_records={new_records}
                        new_field_name_uuid_pairs={new_field_name_uuid_pairs} />
                </tr>)}
            </tbody>
        </table>
    </div>;
}

type ExampleDiffScrapeTableProps = {
    fields: IContextField[];
    context_type: string;
    old_records: IRecord[];
    old_field_name_uuid_pairs: IFieldNameUuidPair[];
    new_records: IRecord[];
    new_field_name_uuid_pairs: IFieldNameUuidPair[];
    diffs: IScrapeEvalDiff[];
}

export function ExampleDiffScrapeTable(props: ExampleDiffScrapeTableProps) {
    const { fields, context_type, old_records, old_field_name_uuid_pairs, new_records, new_field_name_uuid_pairs, diffs } = props;

    const is_object = context_type === CONTEXT_TYPES.object && (0 <= diffs.length && diffs.length <= 2);

    return <div className="px-1 py-4">
        {is_object && <ExampleDiffObjectScrapeTable
            fields={fields}
            old_records={old_records}
            old_field_name_uuid_pairs={old_field_name_uuid_pairs}
            new_records={new_records}
            new_field_name_uuid_pairs={new_field_name_uuid_pairs}
            diffs={diffs} />}
        {!is_object && <ExampleDiffArrayScrapeTable
            fields={fields}
            old_records={old_records}
            old_field_name_uuid_pairs={old_field_name_uuid_pairs}
            new_records={new_records}
            new_field_name_uuid_pairs={new_field_name_uuid_pairs}
            diffs={diffs} />}
    </div>;
}

type ExampleDiffTablesProps = {
    contexts: (IContextNoUUID & { uuid: string })[];
    item: IItemBase;
    scrapes_eval_metrics?: IScrapeEvalMetrics[];
}

export function ExampleDiffTables(props: ExampleDiffTablesProps) {
    const { contexts, item, scrapes_eval_metrics } = props;

    const sheets: {
        context: IContextNoUUID,
        old_records: IRecord[],
        old_field_name_uuid_pairs: IFieldNameUuidPair[],
        new_records?: IRecord[],
        new_field_name_uuid_pairs?: IFieldNameUuidPair[],
        diffs?: IScrapeEvalDiff[]
    }[] = [];

    for (const context of contexts) {
        const old_scrape = item.scrapes.find(s => s.context_uuid === context.uuid);
        const scrape_eval_metrics = scrapes_eval_metrics?.find(s => s.new_scrape.context_uuid === context.uuid);
        if (scrape_eval_metrics === undefined) {
            sheets.push({
                context,
                old_records: old_scrape?.records ?? [],
                old_field_name_uuid_pairs: old_scrape?.field_name_uuid_pairs ?? []
            });
        } else {
            sheets.push({
                context,
                old_records: old_scrape?.records ?? [],
                old_field_name_uuid_pairs: old_scrape?.field_name_uuid_pairs ?? [],
                new_records: scrape_eval_metrics.new_scrape.records,
                new_field_name_uuid_pairs: scrape_eval_metrics.new_scrape.field_name_uuid_pairs,
                diffs: scrape_eval_metrics.diffs
            });
        }
    }

    return <div>
        {sheets.map((sheet, i) => (<div key={i}>
            <div className="px-2 font-medium text-sm">{sheet.context.name}</div>
            {sheet.new_records !== undefined && sheet.diffs !== undefined && <ExampleDiffScrapeTable
                fields={sheet.context.fields}
                context_type={sheet.context.type}
                old_records={sheet.old_records}
                old_field_name_uuid_pairs={sheet.old_field_name_uuid_pairs}
                new_records={sheet.new_records ?? []}
                new_field_name_uuid_pairs={sheet.new_field_name_uuid_pairs ?? []}
                diffs={sheet.diffs ?? []}
            />}
            {(sheet.new_records === undefined || sheet.diffs === undefined) && <ExampleScrapeTable
                fields={sheet.context.fields}
                context_type={sheet.context.type}
                records={sheet.old_records}
                field_name_uuid_pairs={sheet.old_field_name_uuid_pairs}
            />}
        </div>))}
    </div>;
}