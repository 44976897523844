import { ContextType, IScrapeDocument } from "../lib/backend/extractions.types.generated";
import { IContextField } from "./types";

export interface IInitTemplateExample {
    name: string;
    documents: IScrapeDocument[];
    comment: string;
}

export interface IInitTemplate {
    type: ContextType;
    template_name: string;
    fields: IContextField[];
}

export const DEV_TEMPLATE: IInitTemplate = {
    template_name: "Contact information",
    fields: [
        { uuid: "<placeholder>", name: "Name", datatype: "string", type: "extract" },
        { uuid: "<placeholder>", name: "Address", extract: { format: "STREET, CITY, ZIP, COUNTRY" }, datatype: "string", type: "extract" },
        { uuid: "<placeholder>", name: "Phone number", extract: { format: "COUNTRY (AREA) LOCAL" }, datatype: "string", type: "extract" },
        { uuid: "<placeholder>", name: "Email", datatype: "string", type: "extract" }
    ],
    type: "array",
};

export const DEV_EXAMPLE_01: IInitTemplateExample = {
    name: "Multiple addresses",
    documents: [{
        type: "document",
        name: "Multiple addresses",
        pages: [{
            name: "page 0",
            text: "Headquarters of Timber&co are available at 123 Main Street, New York, NY 10001. " +
                "You can reach us at 1-800-555-5555 or info@timber.com.\n" +
                "Department for Artificial Intelligence, Jožef Stefan Institute, Jamova 39, SI- 1000 Ljubljana, Slovenia, " +
                "Tel.: + 386 1 477 3528, Fax.:+ 386 1 477 3851, e-mail: info@ijs.si, web: www.ijs.si\n" +
                "Magna International Inc., 337 Magna Drive, Aurora, Ontario, Canada L4G 7K1, Telephone: +1 905 726 2462"
        }]
    }],
    comment: "Multiple addresses, last without email."
};

export const DEV_EXAMPLE_02: IInitTemplateExample = {
    name: "Single address",
    documents: [{
        type: "document",
        name: "Single address",
        pages: [{
            name: "page 0",
            text: "Blaz Fortuna\n" +
                "Co-founder\n" +
                "extrakt.AI Inc., 4 Palo Alto Square, Palo Alto, CA 94306\n" +
                "P: +1 (650) 283-1854"
        }]
    }],
    comment: "Single address, without email."
};

export const WIZARD_EMPTY: IInitTemplate = {
    template_name: "Empty",
    fields: [],
    type: "array"
};

export const WIZARD_INVOICE: IInitTemplate = {
    template_name: "Invoice",
    fields: [
        { uuid: "<placeholder>", name: "Customer Company Name", datatype: "string", type: "extract" },
        { uuid: "<placeholder>", name: "Invoice Number", datatype: "string", type: "extract" },
        { uuid: "<placeholder>", name: "PO Number", datatype: "string", type: "extract" },
        { uuid: "<placeholder>", name: "Date of Issue", datatype: "string", type: "extract" },
        { uuid: "<placeholder>", name: "Due Date", datatype: "string", type: "extract" },
        { uuid: "<placeholder>", name: "SKU Code", datatype: "string", type: "extract" },
        { uuid: "<placeholder>", name: "Item Name", datatype: "string", type: "extract" },
        { uuid: "<placeholder>", name: "Quantity", datatype: "string", type: "extract" },
        { uuid: "<placeholder>", name: "Unit Price", datatype: "string", type: "extract" },
        { uuid: "<placeholder>", name: "Total Amount", datatype: "string", type: "extract" }
    ],
    type: "array"
};

export const WIZARD_INVOICE_XERO: IInitTemplate = {
    template_name: "Invoice - Xero",
    fields: [
        {
            "uuid": "<placeholder>",
            "name": "*ContactName",
            "datatype": "string",
            "type": "extract",
            "extract": {
                "examples": [
                    "John Smith"
                ],
                "guidelines": [
                    "Person name when available"
                ]
            }
        },
        {
            "uuid": "<placeholder>",
            "name": "EmailAddress",
            "datatype": "string",
            "type": "extract",
            "extract": {}
        },
        {
            "uuid": "<placeholder>",
            "name": "*InvoiceNumber",
            "datatype": "string",
            "type": "extract",
            "extract": {}
        },
        {
            "uuid": "<placeholder>",
            "name": "*InvoiceDate",
            "datatype": "string",
            "type": "extract",
            "extract": {
                "format": "mm/dd/yyyy"
            }
        },
        {
            "uuid": "<placeholder>",
            "name": "*DueDate",
            "datatype": "string",
            "type": "extract",
            "extract": {
                "format": "mm/dd/yyyy"
            }
        },
        {
            "uuid": "<placeholder>",
            "name": "InventoryItemCode",
            "datatype": "number",
            "type": "extract",
            "extract": {}
        },
        {
            "uuid": "<placeholder>",
            "name": "*Description",
            "datatype": "string",
            "type": "extract",
            "extract": {}
        },
        {
            "uuid": "<placeholder>",
            "name": "*Quantity",
            "datatype": "string",
            "type": "extract",
            "extract": {}
        },
        {
            "uuid": "<placeholder>",
            "name": "*UnitAmount",
            "datatype": "string",
            "type": "extract",
            "extract": {}
        },
        {
            "uuid": "<placeholder>",
            "name": "*AccountCode",
            "datatype": "string",
            "type": "extract",
            "extract": {}
        },
        {
            "uuid": "<placeholder>",
            "name": "*TaxType",
            "datatype": "string",
            "type": "extract",
            "extract": {
                "examples": [
                    "20%"
                ]
            }
        }
    ],
    type: "array"
}

export const WIZARD_PURCHASE_ORDER: IInitTemplate = {
    template_name: "Purchase Order",
    fields: [
        { uuid: "<placeholder>", name: "Customer Company Name", datatype: "string", type: "extract" },
        { uuid: "<placeholder>", name: "PO Number", datatype: "string", type: "extract" },
        { uuid: "<placeholder>", name: "SKU Code", datatype: "string", type: "extract" },
        { uuid: "<placeholder>", name: "Item Name", datatype: "string", type: "extract" },
        { uuid: "<placeholder>", name: "Quantity", datatype: "string", type: "extract" },
        { uuid: "<placeholder>", name: "Unit Price", datatype: "string", type: "extract" },
        { uuid: "<placeholder>", name: "Unit Total Price", datatype: "string", type: "extract" }
    ],
    type: "array"
};

export const WIZARD_ORDER_CONFIRMATION: IInitTemplate = {
    template_name: "Order Confirmation",
    fields: [
        { uuid: "<placeholder>", name: "Customer Company Name", datatype: "string", type: "extract" },
        { uuid: "<placeholder>", name: "Order Number", datatype: "string", type: "extract" },
        { uuid: "<placeholder>", name: "SKU Code", datatype: "string", type: "extract" },
        { uuid: "<placeholder>", name: "Unit Price", datatype: "string", type: "extract" },
        { uuid: "<placeholder>", name: "Total Amount", datatype: "string", type: "extract" },
        { uuid: "<placeholder>", name: "Quantity - First", datatype: "string", type: "extract" },
        { uuid: "<placeholder>", name: "Deliver Date - First", datatype: "string", type: "extract" },
        { uuid: "<placeholder>", name: "Quantity - Rest", datatype: "string", type: "extract" },
        { uuid: "<placeholder>", name: "Delivery Date - Rest", datatype: "string", type: "extract" }
    ],
    type: "array"
};

export const WIZARD_CUSTOMS_DECLARATION: IInitTemplate = {
    template_name: "Customs Declaration",
    fields: [
        { uuid: "<placeholder>", name: "Entry Date", datatype: "string", type: "extract" },
        { uuid: "<placeholder>", name: "Manufacturer ID", datatype: "string", type: "extract" },
        { uuid: "<placeholder>", name: "Exporting Country", datatype: "string", type: "extract" },
        { uuid: "<placeholder>", name: "Importer Number", datatype: "string", type: "extract" },
        { uuid: "<placeholder>", name: "Reference Number", datatype: "string", type: "extract" },
        { uuid: "<placeholder>", name: "Merchandise Name", datatype: "string", type: "extract" },
        { uuid: "<placeholder>", name: "Weight", datatype: "string", type: "extract" },
        { uuid: "<placeholder>", name: "Quantity", datatype: "string", type: "extract" },
        { uuid: "<placeholder>", name: "Entered Value", datatype: "string", type: "extract" },
        { uuid: "<placeholder>", name: "Rate", datatype: "string", type: "extract" },
        { uuid: "<placeholder>", name: "Duty and IR TAX", datatype: "string", type: "extract" }
    ],
    type: "array"
};

export const WIZARD_RFQ: IInitTemplate = {
    template_name: "Request for Quotation",
    fields: [
        { uuid: "<placeholder>", name: "Customer Company Name", datatype: "string", type: "extract" },
        { uuid: "<placeholder>", name: "Phone Number", datatype: "string", type: "extract" },
        { uuid: "<placeholder>", name: "Email", datatype: "string", type: "extract" },
        { uuid: "<placeholder>", name: "RFQ ID", datatype: "string", type: "extract" },
        { uuid: "<placeholder>", name: "Deadline", datatype: "string", type: "extract" },
        { uuid: "<placeholder>", name: "Start Date", datatype: "string", type: "extract" },
        { uuid: "<placeholder>", name: "Date of Completion", datatype: "string", type: "extract" },
        { uuid: "<placeholder>", name: "Item Description", datatype: "string", type: "extract" },
        { uuid: "<placeholder>", name: "Quantity", datatype: "string", type: "extract" },
        { uuid: "<placeholder>", name: "Specific Requirements", datatype: "string", type: "extract" }
    ],
    type: "array"
};

export const WIZARD_RESUME: IInitTemplate = {
    template_name: "Resume",
    fields: [
        { uuid: "<placeholder>", name: "Name", datatype: "string", type: "extract" },
        { uuid: "<placeholder>", name: "Email", datatype: "string", type: "extract" },
        { uuid: "<placeholder>", name: "Phone Number", datatype: "string", type: "extract" },
        { uuid: "<placeholder>", name: "LinkedIn", datatype: "string", type: "extract" },
        { uuid: "<placeholder>", name: "Experience", datatype: "string", type: "extract" },
        { uuid: "<placeholder>", name: "Start Date", datatype: "string", type: "extract" },
        { uuid: "<placeholder>", name: "End Date", datatype: "string", type: "extract" },
        { uuid: "<placeholder>", name: "Skills", datatype: "string", type: "extract" }
    ],
    type: "array"
};

export const WIZARD_BANK_STATEMENT: IInitTemplate = {
    template_name: "Bank Statement",
    fields: [
        { uuid: "<placeholder>", name: "Date", datatype: "string", type: "extract" },
        { uuid: "<placeholder>", name: "Amount", datatype: "string", type: "extract" },
        { uuid: "<placeholder>", name: "Payee", datatype: "string", type: "extract" },
        { uuid: "<placeholder>", name: "Description", datatype: "string", type: "extract" },
        { uuid: "<placeholder>", name: "Reference", datatype: "string", type: "extract" },
        { uuid: "<placeholder>", name: "Check Number", datatype: "string", type: "extract" }
    ],
    type: "array"
};

export const WIZARD_SALES_QUOTATION: IInitTemplate = {
    template_name: "Sales Quotation",
    fields: [
        { uuid: "<placeholder>", name: "Supplier Company Name", datatype: "string", type: "extract" },
        { uuid: "<placeholder>", name: "Supplier Company Address", datatype: "string", type: "extract" },
        { uuid: "<placeholder>", name: "Order Number", datatype: "string", type: "extract" },
        { uuid: "<placeholder>", name: "Date", datatype: "string", type: "extract" },
        { uuid: "<placeholder>", name: "SKU", type: "extract", datatype: "string" },
        { uuid: "<placeholder>", name: "Description", type: "extract", datatype: "string" },
        { uuid: "<placeholder>", name: "Quantity", type: "extract", datatype: "string" },
        { uuid: "<placeholder>", name: "Price", type: "extract", datatype: "string" }
    ],
    type: "array"
};

export const WIZARD_VENDOR_EVALUATION: IInitTemplate = {
    template_name: "Vendor Evaluation",
    fields: [
        { uuid: "<placeholder>", name: "Date", type: "extract", datatype: "string" },
        { uuid: "<placeholder>", name: "Evaluation Number", type: "extract", datatype: "string" },
        { uuid: "<placeholder>", name: "Company Name", type: "extract", datatype: "string" },
        { uuid: "<placeholder>", name: "Company Address", type: "extract", datatype: "string" },
        { uuid: "<placeholder>", name: "Criteria", datatype: "string", type: "extract", extract: { examples: ["Timeliness of Deliveries"] } },
        { uuid: "<placeholder>", name: "Score", datatype: "number", type: "extract" }
    ],
    type: "array"
};

export const WIZARD_SHORT_CONTRACT: IInitTemplate = {
    template_name: "Short Contract",
    fields: [
        { uuid: "<placeholder>", name: "Vendor Company", type: "extract", datatype: "string" },
        { uuid: "<placeholder>", name: "Vendor Address", type: "extract", datatype: "string" },
        { uuid: "<placeholder>", name: "Customer Company", type: "extract", datatype: "string" },
        { uuid: "<placeholder>", name: "Customer Address", type: "extract", datatype: "string" },
        { uuid: "<placeholder>", name: "Start Date", type: "extract", datatype: "string" },
        { uuid: "<placeholder>", name: "Termination Date", type: "extract", datatype: "string" },
        { uuid: "<placeholder>", name: "Total Amount", datatype: "string", type: "extract" },
        { uuid: "<placeholder>", name: "Date of Signing", type: "extract", datatype: "string" },
        { uuid: "<placeholder>", name: "Contract signed by Vendor", datatype: "string", type: "extract", extract: { format: "Yes or no" } },
        { uuid: "<placeholder>", name: "Contract signed by Customer", datatype: "string", type: "extract", extract: { format: "Yes or no" } }
    ],
    type: "object"
};

export const WIZARD_SALES_REPORT: IInitTemplate = {
    template_name: "Sales Report",
    fields: [
        { uuid: "<placeholder>", name: "Sales Person", datatype: "string", type: "extract" },
        { uuid: "<placeholder>", name: "Total Sales Amount", datatype: "string", type: "extract" },
        { uuid: "<placeholder>", name: "Date", datatype: "string", type: "extract" },
        { uuid: "<placeholder>", name: "Item Number", type: "extract", datatype: "string" },
        { uuid: "<placeholder>", name: "Item Name", type: "extract", datatype: "string" },
        { uuid: "<placeholder>", name: "Item Price", type: "extract", datatype: "string" },
        { uuid: "<placeholder>", name: "Quantity", type: "extract", datatype: "string" },
        { uuid: "<placeholder>", name: "Total Item Price", type: "extract", datatype: "string" }
    ],
    type: "array"
};

export const WIZARD_ANNUAL_REPORT: IInitTemplate = {
    template_name: "Annual Report",
    fields: [
        { uuid: "<placeholder>", name: "Company", datatype: "string", type: "extract" },
        { uuid: "<placeholder>", name: "Date", datatype: "string", type: "extract" },
        { uuid: "<placeholder>", name: "Profit after Taxes", datatype: "string", type: "extract" },
        { uuid: "<placeholder>", name: "Net Profit", datatype: "string", type: "extract" },
        { uuid: "<placeholder>", name: "Retained earnings", datatype: "string", type: "extract" },
        { uuid: "<placeholder>", name: "Distributable profit", datatype: "string", type: "extract" }
    ],
    type: "object"
};

export const WIZARD_BILL_OF_LADING: IInitTemplate = {
    template_name: "Bill of Lading",
    fields: [
        { uuid: "<placeholder>", name: "Date", type: "extract", datatype: "string" },
        { uuid: "<placeholder>", name: "Bill of Lading Number", type: "extract", datatype: "string" },
        { uuid: "<placeholder>", name: "Buyer Company Name", type: "extract", datatype: "string" },
        { uuid: "<placeholder>", name: "Buyer Contact", type: "extract", datatype: "string" },
        { uuid: "<placeholder>", name: "Vessel Name", type: "extract", datatype: "string" },
        { uuid: "<placeholder>", name: "Carrier Name", type: "extract", datatype: "string" },
        { uuid: "<placeholder>", name: "Way Bill Number", datatype: "string", type: "extract" },
        { uuid: "<placeholder>", name: "Customer Order Number", datatype: "string", type: "extract" },
        { uuid: "<placeholder>", name: "Reference Code", datatype: "string", type: "extract" },
        { uuid: "<placeholder>", name: "Time of arrival", datatype: "string", type: "extract" }
    ],
    type: "array"
};

export const WIZARD_DELIVERY_NOTE: IInitTemplate = {
    template_name: "Delivery Note",
    fields: [
        { uuid: "<placeholder>", name: "Company Name", type: "extract", datatype: "string" },
        { uuid: "<placeholder>", name: "Delivery Note Number", type: "extract", datatype: "string" },
        { uuid: "<placeholder>", name: "Date", type: "extract", datatype: "string" },
        { uuid: "<placeholder>", name: "Shipper Address", type: "extract", datatype: "string" },
        { uuid: "<placeholder>", name: "Shipper Contact", type: "extract", datatype: "string" },
        { uuid: "<placeholder>", name: "Item Name", datatype: "string", type: "extract" },
        { uuid: "<placeholder>", name: "SKU", datatype: "string", type: "extract" },
        { uuid: "<placeholder>", name: "Quantity", datatype: "string", type: "extract" }
    ],
    type: "array"
}

export const WIZARD_SHIPPING_LABEL: IInitTemplate = {
    template_name: "Shipping Label",
    fields: [
        { uuid: "<placeholder>", name: "Service Type", type: "extract", datatype: "string" },
        { uuid: "<placeholder>", name: "Recipient Company Name", type: "extract", datatype: "string" },
        { uuid: "<placeholder>", name: "Recipient Address", type: "extract", datatype: "string" },
        { uuid: "<placeholder>", name: "Tracking Number", type: "extract", datatype: "string" },
        { uuid: "<placeholder>", name: "Sender Company Name", type: "extract", datatype: "string" },
        { uuid: "<placeholder>", name: "Sender Address", type: "extract", datatype: "string" },
        { uuid: "<placeholder>", name: "Additional Info", type: "extract", datatype: "string" }
    ],
    type: "array"
};

export const WIZARD_BOM: IInitTemplate = {
    template_name: "BOM",
    fields: [
        { uuid: "<placeholder>", name: "BOM Level", type: "extract", datatype: "number" },
        { uuid: "<placeholder>", name: "SKU Code", type: "extract", datatype: "string" },
        { uuid: "<placeholder>", name: "Description", type: "extract", datatype: "string" },
        { uuid: "<placeholder>", name: "Yield %", type: "extract", datatype: "string" },
        { uuid: "<placeholder>", name: "Quantity", type: "extract", datatype: "string" },
        { uuid: "<placeholder>", name: "UOM", datatype: "string", type: "extract" }
    ],
    type: "array"
};

export const WIZARD_QUALITY_CONTROL: IInitTemplate = {
    template_name: "Quality Control",
    fields: [
        { uuid: "<placeholder>", name: "Contractor Name", datatype: "string", type: "extract" },
        { uuid: "<placeholder>", name: "Project Number", datatype: "string", type: "extract" },
        { uuid: "<placeholder>", name: "Work Description", type: "extract", datatype: "string" },
        { uuid: "<placeholder>", name: "Subcontractor Name", type: "extract", datatype: "string" },
        { uuid: "<placeholder>", name: "Equipment Used", type: "extract", datatype: "string" },
        { uuid: "<placeholder>", name: "Number of Workers", type: "extract", datatype: "string" },
        { uuid: "<placeholder>", name: "Total Hours", type: "extract", datatype: "string" }
    ],
    type: "array"
};

export const WIZARD_WORK_ORDER: IInitTemplate = {
    template_name: "Work Order",
    fields: [
        { uuid: "<placeholder>", name: "Company Name", type: "extract", datatype: "string" },
        { uuid: "<placeholder>", name: "Work Order Number", type: "extract", datatype: "string" },
        { uuid: "<placeholder>", name: "Issue Number", type: "extract", datatype: "string" },
        { uuid: "<placeholder>", name: "Issue Date", type: "extract", datatype: "string" },
        { uuid: "<placeholder>", name: "Description", datatype: "string", type: "extract" },
        { uuid: "<placeholder>", name: "Unit of Measure", datatype: "string", type: "extract" },
        { uuid: "<placeholder>", name: "Quantity", datatype: "string", type: "extract" }
    ],
    type: "array"
};

export const WIZARD_LONG_CONTRACT: IInitTemplate = {
    template_name: "Long Contract",
    fields: [
        { uuid: "<placeholder>", name: "Contract÷Termination Facts (top 3)≤≥÷reference_line_number", type: "extract", datatype: "string", extract: { examples: ["L124"] } },
        { uuid: "<placeholder>", name: "Contract÷Termination Facts (top 3)≤≥÷Summary", type: "extract", datatype: "string", extract: { examples: ["Either party may terminate this agreement by providing written notice to the other party at least 30 days prior to the desired termination date. In the event of termination, the Client shall pay the Service Provider for all services performed up to the effective date of termination."] } },
        { uuid: "<placeholder>", name: "Contract÷Termination Facts (top 3)≤≥÷Termination Triggers÷reference_line_number", type: "extract", datatype: "string", extract: { examples: ["L130"] } },
        { uuid: "<placeholder>", name: "Contract÷Termination Facts (top 3)≤≥÷Termination Triggers÷Trigger Description", type: "extract", datatype: "string", extract: { examples: ["Breach of contract terms by either party"] } },
        { uuid: "<placeholder>", name: "Contract÷Termination Facts (top 3)≤≥÷Notice Period÷reference_line_number", type: "extract", datatype: "string", extract: { examples: ["L190"] } },
        { uuid: "<placeholder>", name: "Contract÷Termination Facts (top 3)≤≥÷Notice Period÷Duration", type: "extract", datatype: "number", extract: { examples: ["30"] } },
        { uuid: "<placeholder>", name: "Contract÷Termination Facts (top 3)≤≥÷Notice Period÷Unit", type: "extract", datatype: "string", extract: { examples: ["days"] } },
        { uuid: "<placeholder>", name: "Contract÷Pricing÷Currency", type: "extract", datatype: "string", extract: { examples: ["USD"] } },
        { uuid: "<placeholder>", name: "Contract÷Pricing÷Pricing Model", type: "extract", datatype: "string", extract: { examples: ["Subscription"] } },
        { uuid: "<placeholder>", name: "Contract÷Pricing÷Base Price÷reference_line_number", type: "extract", datatype: "string", extract: { examples: ["L123"] } },
        { uuid: "<placeholder>", name: "Contract÷Pricing÷Base Price÷Price", type: "extract", datatype: "number", extract: { examples: ["5000"] } },
        { uuid: "<placeholder>", name: "Contract÷Liability Facts (top 3)≤≥÷reference_line_number", type: "extract", datatype: "string", extract: { examples: ["L123"] } },
        { uuid: "<placeholder>", name: "Contract÷Liability Facts (top 3)≤≥÷Summary", type: "extract", datatype: "string", extract: { examples: ["The Service Provider shall indemnify and hold harmless the Client from any third-party claims arising from the Service Provider's breach of intellectual property rights."] } },
        { uuid: "<placeholder>", name: "Contract÷Liability Facts (top 3)≤≥÷Liability Type", type: "extract", datatype: "string", extract: { examples: ["Indemnification"] } },
        { uuid: "<placeholder>", name: "Contract÷Liability Facts (top 3)≤≥÷Liable Party", type: "extract", datatype: "string", extract: { examples: ["Service Provider"] } },
        { uuid: "<placeholder>", name: "Contract÷Term÷Effective Date÷reference_line_number", type: "extract", datatype: "string", extract: { examples: ["L100"] } },
        { uuid: "<placeholder>", name: "Contract÷Term÷Effective Date÷Date", type: "extract", datatype: "string", extract: { examples: ["2023-01-01"] } },
        { uuid: "<placeholder>", name: "Contract÷Term÷Expiration Date÷reference_line_number", type: "extract", datatype: "string", extract: { examples: ["L110"] } },
        { uuid: "<placeholder>", name: "Contract÷Term÷Expiration Date÷Date", type: "extract", datatype: "string", extract: { examples: ["2023-12-31"] } },
        { uuid: "<placeholder>", name: "Contract÷Maintenance Facts÷Scope Of Maintenance", type: "extract", datatype: "string", extract: { examples: ["The maintenance agreement covers bug fixes, software updates, and technical support for the XYZ software system."] } },
        { uuid: "<placeholder>", name: "Contract÷Maintenance Facts÷Maintenance Period÷reference_line_number", type: "extract", datatype: "string", extract: { examples: ["L123"] } },
        { uuid: "<placeholder>", name: "Contract÷Maintenance Facts÷Maintenance Period÷Start Date", type: "extract", datatype: "string", extract: { examples: ["2023-01-01"] } },
        { uuid: "<placeholder>", name: "Contract÷Maintenance Facts÷Maintenance Period÷End Date", type: "extract", datatype: "string", extract: { examples: ["2023-12-31"] } },
        { uuid: "<placeholder>", name: "Contract÷Maintenance Facts÷Service Level÷reference_line_number", type: "extract", datatype: "string", extract: { examples: ["L252"] } },
        { uuid: "<placeholder>", name: "Contract÷Maintenance Facts÷Service Level÷Response Time", type: "extract", datatype: "string", extract: { examples: ["2 business hours"] } },
        { uuid: "<placeholder>", name: "Contract÷Maintenance Facts÷Service Level÷Resolution Time", type: "extract", datatype: "string", extract: { examples: ["8 business hours"] } },
        { uuid: "<placeholder>", name: "Contract÷Maintenance Facts÷Service Level÷Availability", type: "extract", datatype: "string", extract: { examples: ["99.9%"] } }
    ],
    type: "hierarchical"
}

export const WIZARD_DEMAND_FORECAST_REPORT: IInitTemplate = {
    template_name: "Demand Forecast Report",
    fields: [
        { uuid: "<placeholder>", name: "Material ID", datatype: "string", type: "extract" },
        { uuid: "<placeholder>", name: "Customer ID", datatype: "string", type: "extract" },
        { uuid: "<placeholder>", name: "Date", datatype: "string", type: "extract" },
        { uuid: "<placeholder>", name: "Quantity", datatype: "string", type: "extract" }
    ],
    type: "array"
}