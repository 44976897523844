import { useEffect, useState } from "react";

import * as hi from "@heroicons/react/24/outline";

import { classNames, prettyDuration } from "../lib/utils";
import { IHealth } from "../lib/types";
import { EXTRACT_JOB_STATUS } from "../lib/consts";
import { Backend } from "../lib/backend";
import { useSelector } from "react-redux";
import { selectEnv, selectIsSidebarLarge } from "../lib/scraper.slice";

import { BigButton } from "../components/BigButton";

export function AdminSettings() {
    const env = useSelector(selectEnv);
    const is_sidebar_large = useSelector(selectIsSidebarLarge);

    const [health, setHealth] = useState<IHealth | undefined>(undefined);

    useEffect(() => { Backend.getHealth().then(setHealth); }, []);

    let last_heartbeat_diff = 0;
    let build_diff = 0;
    if (health) {
        last_heartbeat_diff = (new Date().getTime() - new Date(health.last_heartbeat).getTime()) / 1000;
        build_diff = (new Date().getTime() - new Date(health.build_date).getTime()) / 1000;
    }

    return <div className={classNames("flex-row lg:fixed lg:right-0 lg:inset-y-0 overflow-y-auto", is_sidebar_large ? "lg:left-64" : "lg:left-20")}>
        <div className="m-16 mb-6">
            <h2 className="text-xl font-semibold leading-7 text-gray-600 sm:truncate sm:text-3xl sm:tracking-tight">
                Administration
            </h2>
            <div className="pt-5 border-b-4 border-sky-600" />
        </div>
        <div className="m-10 grid grid-cols-1 gap-4 sm:grid-cols-3">
            <BigButton title="Demo Usage" icon={hi.CogIcon} href="/admin/demo-usage" />
            <BigButton title="Prompt Log" icon={hi.CogIcon} href="/admin/prompt-log" />
            <BigButton title="Engagement Statistics" icon={hi.CogIcon} href="https://app.powerbi.com/links/cjtGosbj1F?ctid=8c154d2c-5fa2-4368-955e-9564aa7c8f73&pbi_source=linkShare" />
            <BigButton title="Unit Economics" icon={hi.CogIcon} href="/admin/unit-economics" />
            <BigButton title="Background Jobs" icon={hi.CogIcon} href="/admin/background-jobs" />
            <BigButton title="Import Template" icon={hi.CogIcon} href="/admin/import-template" />
            <BigButton title="Stripe Administration" icon={hi.CogIcon} href="/stripe-admin" />
            <BigButton title="Demo" icon={hi.CogIcon} href="/demo" />
            <BigButton title="Referrals" icon={hi.CogIcon} href="/admin/referrals" />
        </div>

        <div className="m-16 mb-6">
            <h2 className="text-xl font-semibold leading-7 text-gray-600 sm:truncate sm:text-3xl sm:tracking-tight">
                Extraction Jobs
            </h2>
            <div className="pt-5 border-b-4 border-sky-600" />
        </div>
        <div className="m-10 grid grid-cols-1 gap-4 sm:grid-cols-4">
            <BigButton title="Combined" icon={hi.InboxStackIcon} href="/admin/extraction-jobs/combined" />
            <BigButton title="Running" icon={hi.ClockIcon} href={`/admin/extraction-jobs/${EXTRACT_JOB_STATUS.running}`} />
            <BigButton title="Done" icon={hi.CheckIcon} href={`/admin/extraction-jobs/${EXTRACT_JOB_STATUS.done}`} />
            <BigButton title="Error" icon={hi.XMarkIcon} href={`/admin/extraction-jobs/${EXTRACT_JOB_STATUS.error}`} />
        </div>

        <div className="m-10 mt-20 mb-6 text-gray-400 text-xs">
            <table>
                <tbody>
                    <tr>
                        <td className="pr-4">Current Server Time:</td>
                        <td className="font-mono">{health ? health.now_date : "Loading..."}</td>
                    </tr>
                    <tr>
                        <td className="pr-4">Last Heartbeat</td>
                        <td className="font-mono">{health ? health.last_heartbeat : "Loading..."} ({prettyDuration(last_heartbeat_diff)} ago)</td>
                    </tr>
                    <tr>
                        <td className="pr-4">Build Date:</td>
                        <td className="font-mono">{health ? health.build_date : "Loading..."} ({prettyDuration(build_diff)} ago)</td>
                    </tr>
                    <tr>
                        <td className="pr-4">Uptime:</td>
                        <td className="font-mono">{health ? prettyDuration(health.uptime_seconds) : "Loading..."}</td>
                    </tr>
                    <tr>
                        <td className="pr-4">Commit Hash:</td>
                        <td className="font-mono">{health ? health.commit_hash : "Loading..."}</td>
                    </tr>
                    <tr>
                        <td className="pr-4">DB Version:</td>
                        <td className="font-mono">{health ? health.db_version : "Loading..."}</td>
                    </tr>
                    <tr>
                        <td className="pr-4">Env:</td>
                        <td className="font-mono">{env}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>;
};
